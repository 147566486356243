import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material';
import { Transaction, TransactionType } from '@pokerrrr2/server/src/interfaces/transaction.interface';
import TransactionTypeDropdown from '../../components/Dropdowns/TransactionTypeDropdown';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiFetchUsers, apiTransactionCreate, apiTransactionUpdate } from '../../services/api';
import UsersDropdown from '../../components/Dropdowns/UsersDropdown';
import { get, keyBy } from 'lodash';
import useSnackbar from '../../providers/snackbar/useSnackbar';
import { getTransactionDescription } from '../../utils/transactions.util';
import { UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import useAuth from '../../providers/auth/useAuth';
import { currency } from '../../utils/money';

export interface CreateUpdateTransactionDialogProps {
  open?: boolean;
  onRequestClose: (refetch: boolean) => void;
  initialTransaction?: Partial<Transaction>;
}

const defaultValues: Partial<Transaction> = {
  type: TransactionType.BALANCE,
  amount: 0,
  user1Id: null,
  user1: undefined,
  user2Id: null,
  user2: undefined,
  note: '',
};

export default function CreateUpdateTransactionDialog({ open, onRequestClose, initialTransaction }: CreateUpdateTransactionDialogProps) {
  const { authUser } = useAuth();
  const { setSnackbar } = useSnackbar();
  const [transaction, setTransaction] = useState<Partial<Transaction>>({ ...defaultValues, ...initialTransaction });

  const isUpdate = Boolean(initialTransaction?.id);

  useEffect(() => {
    setTransaction({ ...defaultValues, ...initialTransaction });
  }, [open, initialTransaction]);

  const { data: usersRes } = useQuery({
    queryKey: ['users', open],
    queryFn: () => apiFetchUsers(),
    enabled: open,
    keepPreviousData: true,
  });
  const users = useMemo(() => usersRes?.data || [], [usersRes]);
  const agents = useMemo(() => (usersRes?.data || []).filter(u => [UserRole.ADMIN, UserRole.AGENT].includes(u.role)), [usersRes]);
  const usersMap = useMemo(() => keyBy(users, 'id'), [users]);

  const { mutate: createTransaction } = useMutation({
    mutationFn: apiTransactionCreate,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
    },
    onSuccess: async () => {
      setSnackbar({ children: `Transaction successfully created`, severity: 'success' });
      onRequestClose(true);
    },
  });

  const { mutate: updateTransaction } = useMutation({
    mutationFn: apiTransactionUpdate,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
    },
    onSuccess: async () => {
      setSnackbar({ children: `Transaction successfully updated`, severity: 'success' });
      onRequestClose(true);
    },
  });

  const handleCancel = () => {
    onRequestClose(false);
  };

  const handleSave = async () => {
    if (isUpdate) {
      updateTransaction({
        ...initialTransaction,
        ...(transaction as Transaction),
        amount: Number(transaction.amount),
      });
    } else {
      createTransaction({ ...transaction, amount: Number(transaction.amount) });
    }
  };

  const user1Dropdown = useMemo(() => {
    if (transaction.type === TransactionType.SWAP)
      return {
        label: 'From Player',
        items: users,
      };
    if (transaction.type === TransactionType.GIFT || transaction.type === TransactionType.TICKET)
      return {
        label: 'To Player',
        items: users,
      };
    return { label: 'Player', items: users };
  }, [transaction.type, users]);

  const user2Dropdown = useMemo(() => {
    if (transaction.type === TransactionType.SWAP)
      return {
        label: 'To Player',
        items: users,
      };
    if (transaction.type === TransactionType.GIFT || transaction.type === TransactionType.TICKET) return null;
    return { label: 'Agent', items: agents };
  }, [transaction.type, users, agents]);

  const canSave = useMemo(
    () =>
      (transaction.amount && transaction.type === TransactionType.BALANCE && transaction.user1Id && transaction.user2Id) ||
      (transaction.amount && transaction.type === TransactionType.SWAP && transaction.user1Id && transaction.user2Id) ||
      (transaction.amount && transaction.type === TransactionType.GIFT && transaction.user1Id) ||
      (transaction.amount && transaction.type === TransactionType.TICKET && transaction.user1Id),
    [transaction],
  );

  const transactionTypeDropdownExclude = useMemo(
    () => (authUser.role === UserRole.ADMIN || authUser.isAccountant ? [] : [TransactionType.GIFT, TransactionType.TICKET]),
    [authUser],
  );

  return (
    <Dialog
      maxWidth="xl"
      TransitionProps={{
        onEntered: () => {},
      }}
      open={Boolean(open)}
    >
      <Box sx={{ width: '350px' }} />
      <DialogTitle>Create Transaction</DialogTitle>
      <DialogContent dividers sx={{ whiteSpace: 'pre-wrap', overflowY: 'auto' }}>
        <Box sx={{ width: '1000px' }}>
          <Grid container spacing={2} mb={1} alignItems="flex-start">
            <Grid item xs={4}>
              <TransactionTypeDropdown
                label="Type"
                value={transaction.type}
                onChange={(value: TransactionType | null) =>
                  setTransaction({
                    ...transaction,
                    ...defaultValues,
                    type: value!,
                  })
                }
                exclude={transactionTypeDropdownExclude}
              />
            </Grid>
            <Grid item xs={4}>
              <UsersDropdown
                label={user1Dropdown.label}
                value={transaction.user1Id}
                users={user1Dropdown.items}
                onChange={(value: number | null) => {
                  const user1 = usersMap[value!];
                  const user2 = user1.agentId && usersMap[user1.agentId];
                  if (transaction.type === TransactionType.BALANCE && user2) {
                    setTransaction({ ...transaction, user1Id: user1.id, user1, user2Id: user2.id, user2 });
                  } else {
                    setTransaction({ ...transaction, user1Id: user1.id, user1 });
                  }
                }}
              />
              <Typography variant="subtitle2" color="info" pl={1}>
                Current Balance: {transaction.user1Id && currency(Number(usersMap[transaction.user1Id]?.balance))}
              </Typography>
              <Typography variant="subtitle2" color="info" pl={1}>
                Next Balance: {transaction.user1Id && currency(Number(usersMap[transaction.user1Id]?.balance) + Number(transaction.amount))}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {user2Dropdown ? (
                <>
                  <UsersDropdown
                    label={user2Dropdown.label}
                    value={transaction.user2Id}
                    users={user2Dropdown.items}
                    onChange={(value: number | null) => {
                      setTransaction({ ...transaction, user2Id: value!, user2: usersMap[value!] });
                    }}
                  />
                  {transaction.type === TransactionType.SWAP && (
                    <>
                      <Typography variant="subtitle2" color="info" pl={1}>
                        Current Balance: {transaction.user2Id && currency(Number(usersMap[transaction.user2Id]?.balance))}
                      </Typography>
                      <Typography variant="subtitle2" color="info" pl={1}>
                        Next Balance: {transaction.user2Id && currency(Number(usersMap[transaction.user2Id]?.balance) - Number(transaction.amount))}
                      </Typography>
                    </>
                  )}
                </>
              ) : transaction.type === TransactionType.GIFT || transaction.type === TransactionType.TICKET ? (
                <Typography variant="subtitle1" color="info" pt={1}>
                  From Club
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Amount"
                size="small"
                type="number"
                value={transaction.amount}
                onChange={event =>
                  setTransaction({
                    ...transaction,
                    amount: event.target.value as unknown as number,
                  })
                }
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12} mt={5}>
              <TextField
                dir="rtl"
                fullWidth
                label="Note"
                size="small"
                type="number"
                multiline
                rows={2}
                value={transaction.note}
                onChange={event =>
                  setTransaction({
                    ...transaction,
                    note: event.target.value as unknown as string,
                  })
                }
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack ml={1} mr="auto">
          <Typography variant="subtitle2" color="info">
            {getTransactionDescription(transaction, authUser, 'transferring')[0]}
          </Typography>
          <Typography variant="subtitle2" color="info">
            {getTransactionDescription(transaction, authUser, 'transferring')[1]}
          </Typography>
        </Stack>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSave} disabled={!canSave}>
          {isUpdate ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
