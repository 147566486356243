import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { User, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { getUserNameTag } from '../../utils/users.util';
import SatelliteTickets from '../tickets/SatelliteTickets';
import useAuth from '../../providers/auth/useAuth';

export interface UpdateSatelliteTicketsDialogProps {
  onRequestClose: () => void;
  user?: User;
  onResponse?: (user: User) => void;
}

export default function UpdateSatelliteTicketsDialog({ onRequestClose, user, onResponse }: UpdateSatelliteTicketsDialogProps) {
  const { authUser } = useAuth();

  return (
    <Dialog
      maxWidth="xl"
      TransitionProps={{
        onEntered: () => {},
      }}
      open={Boolean(user)}
    >
      <Box sx={{ width: '350px' }} />
      <DialogTitle>User {getUserNameTag(user)} satellite tickets</DialogTitle>
      <DialogContent dividers sx={{ whiteSpace: 'pre-wrap', overflowY: 'auto' }}>
        {user && <SatelliteTickets user={user} enableMenu={authUser.role === UserRole.ADMIN} onResponse={onResponse} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
