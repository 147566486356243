import { IsEnum, IsNumber, IsString } from 'class-validator';
import { TransactionType } from '../../interfaces/transaction.interface';

export enum GetTransactionsDtoFilterEnum {
  MyTransactions = 'MyTransactions',
  MyPlayersTransactions = 'MyPlayersTransactions',
  AllTransactions = 'AllTransactions',
}

export class GetTransactionsDto {
  @IsEnum(GetTransactionsDtoFilterEnum)
  public filter?: GetTransactionsDtoFilterEnum;
}

export class CreateUpdateTransactionDto {
  @IsEnum(TransactionType)
  public type?: TransactionType;

  @IsNumber()
  public loggedById?: number | null;

  @IsNumber()
  public user1Id?: number | null;

  @IsNumber()
  public user2Id?: number | null;

  @IsNumber()
  public amount?: number;

  @IsString()
  public note?: string;
}
