import React from 'react';
import { SYSTEM_PLAYER_NAME, User, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { GridActionsCellItem, GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { filter, keyBy } from 'lodash';
import { dateTimeFormat } from '../../utils/date';
import { Game, GameAction, SupportedGameTypeName } from '@pokerrrr2/server/src/interfaces/game.interface';
import { ClubGameMode, ClubGameRankedPlayer, ClubGameStatus } from '@pokerrrr2/server/src/interfaces/club.interface';
import { GameActionDto } from '@pokerrrr2/server/src/modules/games/games.dto';
import StartIcon from '@mui/icons-material/Start';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { ConfirmDialogMutationParams } from '../../hooks/useConfirmDialogMutation';
import { roundPrecision } from '../../utils/numbers';
import { getUserNameTag } from '../../utils/users.util';
import { currency } from '../../utils/money';
import { NavigateFunction } from 'react-router-dom';
import { RoutePath } from '../../AppRouter';
import { Button } from '@mui/material';

export const parseGameName = (game?: Game) => {
  let name: (number | string | null | undefined)[];
  if (game?.clubGame?.settings?.mode === ClubGameMode.custom) name = ['MMT'];
  else name = ['Cash'];

  if (game?.clubGame?.settings?.name) {
    name.push(game.clubGame.settings.name);
  } else {
    if (game?.clubGame?.settings?.omaha) {
      if (game?.clubGame?.settings?._6Cards) name = name.concat(['Omaha', 'PLO6']);
      else if (game?.clubGame?.settings?._5Cards) name = name.concat(['Omaha', 'PLO5']);
      else name = name.concat(['Omaha', 'PLO4']);
    } else if (game?.clubGame?.settings?.roe) {
      name = name.concat(['ROE', game?.clubGame?.settings?.roeTypes?.map(t => t.replace('-', '')).join('-')]);
    } else if (game?.clubGame?.settings?.ofcType) {
      name = name.concat(['OFC', game?.clubGame?.settings?.ofcType]);
    } else {
      name = name.concat(['Holdem']);
    }

    if (game?.clubGame?.settings?.mode === ClubGameMode.custom) {
      name.push(`${game?.clubGame?.settings?.buyInTicket || 0}+${game?.clubGame?.settings?.buyInTips || 0}`);
      game?.clubGame?.settings?.bounty && name.push(game.clubGame.settings.bounty);
    } else {
      const decimalDivider = game?.clubGame?.settings?.withDecimal ? 100 : 1;
      const bb = (game?.clubGame?.settings?.bb || 0) / decimalDivider;
      name.push(`${roundPrecision(bb / 2, 1)}/${roundPrecision(bb)}`);
    }
  }
  name.push(`${game?.clubGame?.settings?.maxPlayer}p`);

  return name.join(' ');
};

export const getGamesTableColumns = (
  authUser: User,
  gameAction: (params: GameActionDto, dialogParams?: ConfirmDialogMutationParams) => void,
  users: User[],
  setGameForUpdateRanking: (game: Game) => void,
  navigate: NavigateFunction,
): GridColDef[] => {
  const usersCodeMap = keyBy(users, 'code');
  return filter([
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: params => {
        return <Button onClick={() => navigate(RoutePath.Games + `/${params.id}`)}>Info</Button>;
      },
    },
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'name',
      headerName: 'Name',
      width: 350,
      valueGetter: ({ row }: GridValueGetterParams<Game>) => parseGameName(row),
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
    },
    {
      field: 'clubGame.createdAt',
      headerName: 'Created At',
      width: 150,
      valueGetter: ({ row }: GridValueGetterParams<Game>) => dateTimeFormat(row.clubGame.createdAt),
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'openedById',
      headerName: 'Opened By',
      width: 200,
      valueGetter: ({ row }: GridValueGetterParams<Game>) => getUserNameTag(row.openedBy, SYSTEM_PLAYER_NAME),
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'closedById',
      headerName: 'Closed By',
      width: 200,
      valueGetter: ({ row }: GridValueGetterParams<Game>) =>
        row.status === ClubGameStatus.END ? getUserNameTag(row.closedBy, SYSTEM_PLAYER_NAME) : '',
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'raking.club',
      headerName: 'Club Rake',
      width: 150,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams<Game>) => Number(row.raking.club),
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'raking.total',
      headerName: 'Total Rake',
      width: 150,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams<Game>) => Number(row.raking.total),
    },
    ([UserRole.ADMIN].includes(authUser.role) || authUser.isRunner) && {
      field: 'actions',
      type: 'actions',
      getActions: ({ row }: GridRowParams<Game>) =>
        filter([
          [ClubGameStatus.PREPARE].includes(row.status) && (
            <GridActionsCellItem
              icon={<StartIcon />}
              label="Start Game"
              showInMenu
              onClick={() =>
                gameAction(
                  {
                    oid: row.oid,
                    action: GameAction.Start,
                  },
                  { text: 'This will manually start the game.' },
                )
              }
            />
          ),
          [ClubGameStatus.PLAYING, ClubGameStatus.WAITING].includes(row.status) && (
            <GridActionsCellItem
              icon={<PauseIcon />}
              label="Pause Game"
              showInMenu
              onClick={() =>
                gameAction(
                  {
                    oid: row.oid,
                    action: GameAction.Pause,
                  },
                  { text: 'This will manually pause the game.' },
                )
              }
            />
          ),
          [ClubGameStatus.PAUSE].includes(row.status) && (
            <GridActionsCellItem
              icon={<PlayArrowIcon />}
              label="Resume Game"
              showInMenu
              onClick={() =>
                gameAction(
                  {
                    oid: row.oid,
                    action: GameAction.Resume,
                  },
                  { text: 'This will manually resume the game.' },
                )
              }
            />
          ),
          ![ClubGameStatus.END].includes(row.status) && (
            <GridActionsCellItem
              icon={<PowerSettingsNewIcon />}
              label="Terminate Game"
              showInMenu
              onClick={() =>
                gameAction(
                  {
                    oid: row.oid,
                    action: GameAction.Terminate,
                  },
                  { text: 'This will manually terminate the game.' },
                )
              }
            />
          ),
          authUser.isAccountant && [ClubGameStatus.END].includes(row.status) && row.clubGame.ranking.length && (
            <GridActionsCellItem
              icon={<WorkspacePremiumIcon />}
              label="Update Game Ranking"
              showInMenu
              onClick={() => setGameForUpdateRanking(row)}
            />
          ),
        ]),
    },
    {
      field: 'score',
      headerName: 'Score',
      width: 3000,
      valueGetter: ({ row }: GridValueGetterParams<Game>) => {
        const scores: string[] = [];
        row.clubGame.ranking.forEach(({ playerCode, place, diff, satelliteTicket, onlineSatelliteTicketUsedAmount }) => {
          if (playerCode && usersCodeMap[playerCode]) {
            const diffText = currency(diff, { withSymbol: false });
            const satelliteTicketUsedText = onlineSatelliteTicketUsedAmount
              ? ` + (${currency(onlineSatelliteTicketUsedAmount, { withSymbol: false })} from tickets)`
              : '';
            const satelliteTicketText = satelliteTicket?.prize
              ? ` + Ticket(${currency(satelliteTicket?.prize, { withSymbol: false })})`
              : satelliteTicket?.onlineGameType
              ? ` + Ticket(${SupportedGameTypeName[satelliteTicket.onlineGameType]})`
              : '';
            const prizeText = diffText + satelliteTicketUsedText + satelliteTicketText;
            if (![UserRole.ADMIN, UserRole.AGENT].includes(authUser.role)) {
              scores.push(`l ❂${place} ${prizeText}`);
            } else {
              scores.push(`l ❂${place} (${getUserNameTag(usersCodeMap[playerCode])}) ${prizeText} `);
            }
          }
        });
        return scores.join('\n');
      },
    },
    {
      field: 'points',
      headerName: 'Points',
      width: 2000,
      valueGetter: ({ row }: GridValueGetterParams<Game>) => {
        const scores: string[] = [];
        row.clubGame.ranking.forEach(({ playerCode, place, mmtPoints }) => {
          if (playerCode && usersCodeMap[playerCode]) {
            const mmtPointsText = currency(mmtPoints, { withSymbol: false });
            if (![UserRole.ADMIN, UserRole.AGENT].includes(authUser.role)) {
              scores.push(`l ❂${place} ${mmtPointsText}`);
            } else if (mmtPoints) {
              scores.push(`l ❂${place} (${getUserNameTag(usersCodeMap[playerCode])}) ${mmtPointsText} `);
            }
          }
        });
        return scores.join('\n');
      },
    },
  ] as GridColDef[]);
};

export const GetGameUsersTableColumns = (authUser: User, users?: User[]): GridColDef[] => {
  return filter([
    {
      field: 'displayName',
      headerName: 'Name',
      width: 150,
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'playerCode',
      headerName: 'Code',
      width: 100,
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'bc',
      headerName: 'B/C',
      width: 150,
      valueGetter: ({ row }: GridValueGetterParams<ClubGameRankedPlayer>) => {
        if (users) {
          const user = users.find(user => {
            return user.code === row.playerCode;
          });

          if (user) {
            return `${currency(user.balance)}/${currency(user.cup)}`;
          }
        }

        return 'N/A';
      },
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'diff',
      headerName: 'Score',
      width: 100,
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'buyIn',
      headerName: 'Buy In',
      width: 100,
    },
    {
      field: 'stack',
      headerName: 'Stack',
      width: 100,
    },
    {
      field: 'handCount',
      headerName: 'Hand Count',
      width: 130,
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'rake',
      headerName: 'Rake Total',
      width: 130,
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'bought',
      headerName: 'Bought',
      width: 100,
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'addon',
      headerName: 'Addon',
      width: 100,
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'bounty',
      headerName: 'Bounty',
      width: 100,
    },
  ] as GridColDef[]);
};
