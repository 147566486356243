import React, { MutableRefObject, useMemo } from 'react';
import { DataGrid, DataGridProps, GridApiCommon } from '@mui/x-data-grid';
import StylesService from '../../services/styles';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowIdGetter, GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { cloneDeep } from 'lodash';
import { UncapitalizeObjectKeys } from '@mui/x-data-grid/internals/utils';
import { GridSlotsComponent } from '@mui/x-data-grid/models/gridSlotsComponent';
import { GridSlotsComponentsProps } from '@mui/x-data-grid/models/gridSlotsComponentsProps';

interface DataTableProps<R extends GridValidRowModel = any> {
  apiRef?: MutableRefObject<GridApiCommon> | undefined;
  columns: DataGridProps<R>['columns'];
  rows: DataGridProps<R>['rows'];
  paginationModel?: Partial<GridPaginationModel>;
  sortModel?: GridSortModel;
  processRowUpdate?: (newRow: R, oldRow: R) => Promise<R>;
  slots?: UncapitalizeObjectKeys<Partial<GridSlotsComponent>>;
  slotProps?: GridSlotsComponentsProps;
  hideFooter?: boolean;
  getRowId?: GridRowIdGetter<R>;
}

export default function DataTable<R extends GridValidRowModel = any>({
  apiRef,
  columns: columnsProp,
  rows,
  paginationModel,
  sortModel,
  processRowUpdate,
  slots,
  slotProps,
  hideFooter,
  getRowId,
}: DataTableProps<R>) {
  const columns = useMemo(() => {
    const columnsClone = cloneDeep(columnsProp);
    const columnsLastIndex = columnsClone.length - 1;
    if (columnsClone[0].width) columnsClone[0].width += 30;
    if (columnsClone[columnsLastIndex].width) columnsClone[columnsLastIndex].width! += 30;
    return columnsClone;
  }, [columnsProp]);

  return (
    <>
      <DataGrid
        getRowId={getRowId ? getRowId : (row: R) => row.id}
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        getRowHeight={() => 'auto'}
        slots={slots}
        slotProps={slotProps}
        initialState={{
          pagination: { paginationModel },
          sorting: { sortModel },
        }}
        pageSizeOptions={paginationModel?.pageSize ? [paginationModel?.pageSize] : undefined}
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        hideFooter={hideFooter}
        processRowUpdate={processRowUpdate}
        sx={{
          backgroundColor: StylesService.colors.white,
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '103px',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '103px',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:first-of-type': {
            paddingLeft: '30px',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:last-of-type': {
            paddingRight: '30px',
          },
          '& .MuiDataGrid-row .MuiDataGrid-cell:first-of-type': {
            paddingLeft: '30px',
          },
          '& .MuiDataGrid-row .MuiDataGrid-cell:last-of-type': {
            paddingRight: '30px',
          },
          '& .MuiDataGrid-cell': {
            maxHeight: 'none !important',
          },
          '& .MuiDataGrid-cell.white-space-pre': {
            whiteSpace: 'pre !important',
          },
          '& .MuiDataGrid-cell.text-align-right .MuiDataGrid-cellContent': {
            textAlign: 'right !important',
            width: '100%',
          },
          '& .MuiDataGrid-row': {
            minHeight: '52px !important',
            maxHeight: 'none !important',
          },
        }}
      />
    </>
  );
}
