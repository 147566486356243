import React from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

export interface SearchFieldProps extends Omit<TextFieldProps, 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
}

function SearchField({ value, onChange, ...props }: SearchFieldProps) {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value || '');
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChange?.('');
  };

  return (
    <TextField
      label="Search"
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {Boolean(value) && (
              <IconButton onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            )}
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...props}
      value={value}
      onChange={handleSearchChange}
    />
  );
}

export default SearchField;
