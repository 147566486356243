import { UserRef } from './user.interface';

export enum TransactionType {
  BALANCE = 'BALANCE',
  GIFT = 'GIFT',
  SWAP = 'SWAP',
  TICKET = 'TICKET',
}

export const AllTransactionTypeValues = Object.values(TransactionType);

export interface Transaction {
  id: number;

  type: TransactionType;
  loggedById?: number | null;
  loggedBy?: UserRef;
  user1Id?: number | null;
  user1?: UserRef;
  user2Id?: number | null;
  user2?: UserRef;
  amount: number;
  note?: string;

  createdAt: Date;
  updatedAt: Date;
}
