import React from 'react';
import { IconButton, MenuProps } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuButton from './MenuButton';

export interface ActionsMenuButtonProps {
  children?: MenuProps['children'];
}

function ActionsMenuButton({ children }: ActionsMenuButtonProps) {
  return (
    <MenuButton
      trigger={
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      }
    >
      {children}
    </MenuButton>
  );
}

export default ActionsMenuButton;
