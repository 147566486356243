import React, { useMemo } from 'react';
import useAuth from '../../providers/auth/useAuth';
import { Box, Card, Stack, Typography } from '@mui/material';
import { ReactComponent as GamblerPokerChipsIcon } from '../../assets/svgs/gambler-poker-chips.svg';
import { ReactComponent as MedalIcon } from '../../assets/svgs/medal.svg';
import { ReactComponent as GivePokerChipsIcon } from '../../assets/svgs/give-poker-chips.svg';
import { ReactComponent as GoldCoinsIcon } from '../../assets/svgs/gold-coins.svg';
import { ReactComponent as CycleIcon } from '../../assets/svgs/cycle.svg';
import { currency } from '../../utils/money';
import { getPlayerLiveBalanceAndSatellites, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { useQuery } from '@tanstack/react-query';
import { apiFetchDconfig } from '../../services/api';
import LocalActivityIcon from '@mui/icons-material/LocalActivityOutlined';

function RootScreen() {
  const { authUser } = useAuth();

  const { data: hostGoldRes, isLoading: hostGoldResIsLoading } = useQuery({
    queryKey: ['hostGold', authUser],
    queryFn: () => apiFetchDconfig('hostGold'),
    refetchInterval: 30_000,
    enabled: authUser.role === UserRole.ADMIN,
  });
  const hostGold = useMemo(() => hostGoldRes?.data.value, [hostGoldRes]);

  const { balance, liveRanking, satellites } = authUser;
  const { liveBalance, liveSatellites } = useMemo(
    () => getPlayerLiveBalanceAndSatellites(balance, liveRanking, satellites),
    [balance, liveRanking, satellites],
  );

  return (
    <Stack sx={{ p: 3, gap: 3, flexDirection: 'row', flexWrap: 'wrap' }}>
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '250px', height: '140px', p: 2 }}>
        <Typography variant="h6">יתרת מחזור נוכחי</Typography>
        <Stack sx={{ flexDirection: 'row', gap: 3 }}>
          <GamblerPokerChipsIcon height={44} style={{ padding: 3 }} />
          <Typography variant="h6" mt={1}>
            {currency(liveBalance)}
          </Typography>
        </Stack>
        <Typography variant="subtitle2">* your cup limit is {currency(authUser.cup)}</Typography>
      </Card>
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '250px', height: '140px', p: 2 }}>
        <Typography variant="h6">נקודות</Typography>
        <Stack sx={{ flexDirection: 'row', gap: 3 }}>
          <MedalIcon height={41} style={{ padding: 4 }} />
          <Typography variant="h6" mt={1}>
            {currency(authUser.mmtPoints, { withSymbol: false })}
          </Typography>
        </Stack>
        <Typography variant="subtitle2">* מהטורנירים המרכזיים</Typography>
      </Card>
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '250px', height: '140px', p: 2 }}>
        <Typography variant="h6">סגירת מחזור קודם</Typography>
        <Stack sx={{ flexDirection: 'row', gap: 3 }}>
          <CycleIcon height={35} style={{ padding: 7, paddingRight: 0, paddingLeft: 0 }} />
          <Stack>
            <Stack alignItems="center" flexDirection="row" gap={2}>
              <Typography variant="subtitle2">Prev Balance</Typography>
              <Typography variant="h6">{currency(authUser.cycle.balance)}</Typography>
            </Stack>

            {([UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) || authUser.rakePercentage || authUser.personalRakePercentage) && (
              <Stack alignItems="center" flexDirection="row" gap={2}>
                <Typography variant="subtitle2">Prev RakeBack</Typography>
                <Typography variant="h6">{currency(authUser.cycle.rakeBack)}</Typography>
              </Stack>
            )}

            <Stack alignItems="center" flexDirection="row" gap={2}>
              <Typography variant="subtitle2">Prev Points</Typography>
              <Typography variant="h6">{currency(authUser.cycle.mmtPoints, { withSymbol: false })}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '250px', height: '140px', p: 2 }}>
        <Typography variant="h6">כרטיסים בתוקף</Typography>
        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          <LocalActivityIcon style={{ padding: 7, fontSize: 44 }} />
          <Stack>
            <Box>
              <Typography variant="subtitle2">Online Tickets</Typography>
              <Typography variant="h6">{liveSatellites.filter(t => !t.usedAt && t.onlineGameType).length}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">Offline Tickets</Typography>
              <Typography variant="h6">{liveSatellites.filter(t => !t.usedAt && !t.onlineGameType).length}</Typography>
            </Box>
          </Stack>
        </Stack>
      </Card>
      {([UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) || authUser.rakePercentage || authUser.personalRakePercentage) && (
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '250px', height: '140px', p: 2 }}>
          <Typography variant="h6">RakeBack</Typography>
          <Stack sx={{ flexDirection: 'row', gap: 3 }}>
            <GivePokerChipsIcon height={50} />
            <Typography variant="h6" mt={1}>
              {currency(authUser.rakeBack)}
            </Typography>
          </Stack>
          <Typography variant="subtitle2" dir="rtl">
            * {authUser.personalRakePercentage}% מהרייק שלך
          </Typography>
          {[UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) && (
            <Typography variant="subtitle2" dir="rtl">
              * {authUser.rakePercentage}% מהרייק של השחקנים שלך פחות הרייק האישי שלהם
            </Typography>
          )}
        </Card>
      )}
      {[UserRole.ADMIN].includes(authUser.role) && (
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '250px', height: '140px', p: 2 }}>
          <Typography variant="h6">Host Gold</Typography>
          <Stack sx={{ flexDirection: 'row', gap: 3 }}>
            <GoldCoinsIcon height={50} width={50} />
            <Typography variant="h6" mt={1}>
              {hostGoldResIsLoading ? '...Loading' : currency(hostGold)}
            </Typography>
          </Stack>
          <Typography variant="subtitle2">
            <wbr />
          </Typography>
        </Card>
      )}
    </Stack>
  );
}

export default RootScreen;
