import React, { useCallback } from 'react';
import Dropdown, { DropdownProps } from './Dropdown';
import { UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';

const roles: DropdownProps['options'] = Object.values(UserRole).map(value => ({
  value: value,
  label: value,
}));

interface UserRoleDropdownProps {
  value?: UserRole | null;
  onChange?: (value: UserRole | null) => void;
  label?: string;
}

export default function UserRoleDropdown({ value: valueProp, onChange: onChangeProp, label = 'Role' }: UserRoleDropdownProps) {
  const onChange = useCallback((value?: string | null) => onChangeProp?.((value as UserRole) || null), [onChangeProp]);
  const value = valueProp === undefined || valueProp === null ? '' : String(valueProp);
  return <Dropdown value={value} onChange={onChange} options={roles} label={label} />;
}
