import { some } from 'lodash';

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function fuzzyMatch(pattern: string = '', str: string | string[] = '', regFlags: string = 'i') {
  if (!pattern) return true;
  pattern =
    '.*' +
    pattern
      .split('')
      .map(l => `${escapeRegExp(l)}.*`)
      .join('');
  const re = new RegExp(pattern, regFlags);
  return Array.isArray(str) ? some(str.map(s => re.test(s))) : re.test(str);
}

export function containMatch(pattern: string | undefined | null = '', str: string | string[] = '', regFlags: string = 'i') {
  if (!pattern) return true;
  pattern =
    '.*' +
    pattern
      .split('')
      .map(l => `${escapeRegExp(l)}`)
      .join('') +
    '.*';
  const re = new RegExp(pattern, regFlags);
  return Array.isArray(str) ? some(str.map(s => re.test(s))) : re.test(str);
}
