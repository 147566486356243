import { Transaction, TransactionType } from '@pokerrrr2/server/src/interfaces/transaction.interface';
import { getUserNameTag } from './users.util';
import { currency } from './money';
import { User, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { compact } from 'lodash';

export function getTransactionDescription(transaction: Partial<Transaction>, authUser: User, action: 'transferred' | 'transferring' = 'transferred') {
  const user1Name = `(${getUserNameTag(transaction.user1)})`;
  const user2Name = `(${getUserNameTag(transaction.user2)})`;
  const amount = transaction.amount || 0;
  const canSeeBoth = [UserRole.ADMIN].includes(authUser.role) || authUser.isAccountant;
  if (transaction.type === TransactionType.BALANCE) {
    if (amount >= 0) {
      return [`${user1Name} ${action} ${currency(amount)} to ${user2Name}`, `${user1Name} balance ↥`];
    } else {
      return [`${user2Name} ${action} ${currency(-amount)} to ${user1Name}`, `${user1Name} balance ↧`];
    }
  } else if (transaction.type === TransactionType.SWAP) {
    if (amount >= 0) {
      return [
        `${user2Name} ${action} ${currency(amount)} to ${user1Name}`,
        compact([`${user1Name} balance ↥`, canSeeBoth && `${user2Name} balance ↧`]).join(' | '),
      ];
    } else {
      return [
        `${user1Name} ${action} ${currency(-amount)} to ${user2Name}`,
        compact([canSeeBoth && `${user2Name} balance ↥`, `${user1Name} balance ↧`]).join(' | '),
      ];
    }
  } else if (transaction.type === TransactionType.GIFT || transaction.type === TransactionType.TICKET) {
    if (amount >= 0) {
      return [`club ${action} ${currency(amount)} to ${user1Name}`, `${user1Name} balance ↥`];
    } else {
      return [`${user1Name} ${action} ${currency(-amount)} to club`, `${user1Name} balance ↧`];
    }
  }
  return ['', ''];
}
