import { User, UserRake, UserRef, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { compact } from 'lodash';
import { currency } from './money';

export function getUserNameTag(user?: UserRef, defaultValue: string = 'UNKNOWN') {
  return user ? compact([`#${user.code}`, user.inGameName, user.name]).join(' - ') : defaultValue;
}

export function getRakeDisplay(rake: UserRake, authUser: User) {
  if (!rake) return '';
  const total = currency(rake?.total, { withSymbol: false });
  const club = currency(rake?.club, { withSymbol: false });
  const agent = currency(rake?.agent, { withSymbol: false });
  const personal = currency(rake?.personal, { withSymbol: false });
  if (authUser.role === UserRole.ADMIN || authUser.isAccountant) {
    return `${total}⥂${club}|${agent}|${personal} ❂`;
  } else {
    return `${agent}|${personal} ❂`;
  }
}
