export const dateTimeFormat = (date?: number | string | Date) => {
  if (!date) return '';
  date = new Date(date);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const newDateWithAdd = (hours: number, minutes: number = 0, date?: number | string | Date) => {
  date = date ? new Date(date) : new Date();
  date.setMinutes(date.getMinutes() + minutes + hours * 60);
  return date;
};

export const newDateWithHourSet = (hour: number, date?: number | string | Date) => {
  date = date ? new Date(date) : new Date();
  date.setHours(hour);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const newDateRoundNearestMinutes = (minutes: number, date?: number | string | Date) => {
  date = date ? new Date(date) : new Date();
  const minutesInMs = 1000 * 60 * minutes;
  return new Date(Math.round(date.getTime() / minutesInMs) * minutesInMs);
};

export const newDateInTimeZone = (timeZone: string) => {
  return new Date(new Date().toLocaleString('en-US', { timeZone }));
};

export const newDateIsraelTimeZone = () => {
  return newDateInTimeZone('Asia/Jerusalem');
};

export const toTime = (seconds: number) => {
  var date = new Date(seconds * 1000);
  return date.toISOString().slice(11, 19);
};
