import { roundPrecision } from './numbers';

export function currency(
  value: number | string | null | undefined,
  options?: {
    precision?: number;
    withSymbol?: boolean;
  },
) {
  const { precision = 2, withSymbol = true } = options || {};
  return roundPrecision(value || 0, precision).toLocaleString('en') + (withSymbol ? ' ❂' : '');
}
