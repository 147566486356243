import React, { ReactNode, useCallback, useState } from 'react';
import SnackbarContext from './SnackbarContext';
import { Alert, AlertProps, Snackbar } from '@mui/material';

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const handleCloseSnackbar = useCallback(() => setSnackbar(null), [setSnackbar]);

  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      {children}
      {snackbar ? (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      ) : null}
    </SnackbarContext.Provider>
  );
};
