import React, { useCallback, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const useDataTableConfirmUpdate = <R = any,>(
  onMutation?: (newRow: R) => Promise<R | undefined>,
  computeMutation?: (newRow: R, oldRow: R) => string | null,
  computeMutationValidation?: (newRow: R, oldRow: R) => string | null,
) => {
  const [promiseArguments, setPromiseArguments] = useState<{
    resolve: (value: R | PromiseLike<R>) => void;
    newRow: R;
    oldRow: R;
  } | null>(null);
  const noButtonRef = useRef<HTMLButtonElement>(null);

  const processDataTableRowUpdate = useCallback(
    (newRow: R, oldRow: R) =>
      new Promise<R>(resolve => {
        const mutation = computeMutation?.(newRow, oldRow);
        const mutationValidation = computeMutationValidation?.(newRow, oldRow);
        if (mutation || mutationValidation) {
          // Save the arguments to resolve the promise later
          setPromiseArguments({ resolve, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    [computeMutation, computeMutationValidation],
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments!;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, resolve } = promiseArguments!;

    try {
      // Make the HTTP request to save in the backend
      const response = await onMutation?.(newRow);
      resolve(response || newRow);
      setPromiseArguments(null);
    } catch (error) {
      resolve(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderDataTableConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutationValidation = computeMutationValidation?.(newRow, oldRow);

    if (mutationValidation) {
      return (
        <Dialog maxWidth="xl" TransitionProps={{ onEntered: handleEntered }} open={!!promiseArguments}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent dividers>{mutationValidation}</DialogContent>
          <DialogActions>
            <Button ref={noButtonRef} onClick={handleNo}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    const mutation = computeMutation?.(newRow, oldRow);
    return (
      <Dialog maxWidth="xl" TransitionProps={{ onEntered: handleEntered }} open={!!promiseArguments}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>{`Pressing 'Yes' will change ${mutation}.`}</DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return {
    renderDataTableConfirmDialog,
    processDataTableRowUpdate,
  };
};
