import React from 'react';
import useAuth from '../../providers/auth/useAuth';
import { Stack, Typography } from '@mui/material';
import SatelliteTickets from './SatelliteTickets';

function TicketsScreen() {
  const { authUser } = useAuth();

  return (
    <Stack sx={{ p: 3, gap: 3 }}>
      <ul dir="rtl" style={{ margin: 0, paddingRight: 16 }}>
        <li>
          <Typography variant="subtitle2">
            כרטיסי כניסה לטורניר אונליין יחושבו אוטומטית כחלק מהבאלנס בכניסה לטורניר המתאים ויתווספו אוטומטית לבאלנס בסיום הטורניר, כלומר הבאלנס
            יעודכן בתוצאת הטורניר כרגיל אך בתוספת עלות הכניסה שהכרטיס מזכה
            <br />
            אם ברשותך יותר מכרטיס אחד מתאים, ימומש כרטיס עבור כל כניסה
          </Typography>
        </li>
        <li>
          <Typography variant="subtitle2">
            הזוכה בשארית טורניר המזכה בכרטיס כניסה לטורניר אונליין יקבל את השארית ישירות לבלאנס בסיום הטורניר המזכה
          </Typography>
        </li>
        <li>
          <Typography variant="subtitle2">כרטיסי סאטלייט הניתנים למימוש בטורניר אופליין ימומשו ע״י אדמין במערכת</Typography>
        </li>
      </ul>

      <SatelliteTickets user={authUser} />
    </Stack>
  );
}

export default TicketsScreen;
