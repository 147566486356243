import React from 'react';
import { User, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { GridActionsCellItem, GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { filter } from 'lodash';
import { dateTimeFormat } from '../../utils/date';
import { Transaction } from '@pokerrrr2/server/src/interfaces/transaction.interface';
import EditIcon from '@mui/icons-material/EditOutlined';
import { getUserNameTag } from '../../utils/users.util';
import { getTransactionDescription } from '../../utils/transactions.util';

export const getTransactionsTableColumns = (authUser: User, setTransactionCreateOrUpdate: (transaction: Transaction) => void): GridColDef[] => {
  return filter([
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 600,
      valueGetter: ({ row }: GridValueGetterParams<Transaction>) => getTransactionDescription(row, authUser)[0],
    },
    {
      field: 'directions',
      headerName: 'Directions',
      width: 600,
      valueGetter: ({ row }: GridValueGetterParams<Transaction>) => getTransactionDescription(row, authUser)[1],
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      type: 'number',
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 500,
      type: 'string',
      sortable: false,
      cellClassName: 'white-space-pre text-align-right',
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 150,
      valueGetter: ({ row }: GridValueGetterParams<Transaction>) => dateTimeFormat(row.updatedAt),
    },
    ([UserRole.ADMIN].includes(authUser.role) || authUser.isAccountant) && {
      field: 'loggedById',
      headerName: 'Logged By',
      width: 200,
      valueGetter: ({ row }: GridValueGetterParams<Transaction>) => getUserNameTag(row.loggedBy),
    },
    authUser.isAccountant && {
      field: 'actions',
      type: 'actions',
      getActions: ({ row }: GridRowParams<Transaction>) =>
        filter([<GridActionsCellItem icon={<EditIcon />} label="Update Transaction" showInMenu onClick={() => setTransactionCreateOrUpdate(row)} />]),
    },
  ] as GridColDef[]);
};
