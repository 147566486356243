import React, { Suspense } from 'react';
import { createBrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { IAuthContextOptionalUser } from './providers/auth/AuthContext';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import queryString from 'query-string';
import useAuth from './providers/auth/useAuth';
import AppBar from './layout/AppBar';

import RootScreen from './routes/root/RootScreen';
import LoginScreen from './routes/auth/LoginScreen';
import PasswordResetScreen from './routes/auth/PasswordResetScreen';
import UsersScreen from './routes/users/UsersScreen';
import GamesScreen from './routes/games/GamesScreen';
import GameScreen from './routes/games/GameScreen';
import ActiveGamesScreen from './routes/games/ActiveGamesScreen';
import TransactionsScreen from './routes/transactions/TransactionsScreen';
import PolicyScreen from './routes/policy/PolicyScreen';
import TicketsScreen from './routes/tickets/TicketsScreen';
import PointsScreen from './routes/points/PointsScreen';
import CycleScreen from './routes/cycle/CycleScreen';

export enum RoutePath {
  Home = '/',
  Login = '/login',
  PasswordReset = '/password-reset',
  Users = '/users',
  Games = '/games',
  Game = '/games/:id',
  ActiveGames = '/games/active',
  Tickets = '/tickets',
  Points = '/points',
  Transactions = '/transactions',
  Policy = '/policy',
  CurrentCycle = '/current-cycle',
  PreviousCycle = '/previous-cycle',
}

const PermittedRoute = ({ authorized, replaceRoute }: { authorized: boolean; replaceRoute: string }) => {
  const { authUser } = useAuth();

  return authorized ? (
    <div style={{ width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column' }}>
      {Boolean(authUser) && <AppBar />}
      <Outlet />
    </div>
  ) : (
    <Navigate to={{ pathname: replaceRoute }} replace />
  );
};

function AppRouterComponent() {
  const { authUser } = useAuth() as IAuthContextOptionalUser;
  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route element={<PermittedRoute authorized={!Boolean(authUser)} replaceRoute={RoutePath.Home} />}>
          <Route path={RoutePath.Login} element={<LoginScreen />} />
          <Route path={RoutePath.PasswordReset} element={<PasswordResetScreen />} />
        </Route>

        <Route element={<PermittedRoute authorized={Boolean(authUser)} replaceRoute={RoutePath.Login} />}>
          {authUser?.role && [UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) && (
            <>
              <Route path={RoutePath.Users} element={<UsersScreen />} />
            </>
          )}
          {authUser?.role && ([UserRole.ADMIN].includes(authUser.role) || authUser.isAccountant) && (
            <>
              <Route path={RoutePath.Transactions} element={<TransactionsScreen />} />
            </>
          )}
          {authUser?.isAccountant && (
            <>
              <Route path={RoutePath.CurrentCycle} element={<CycleScreen />} />
              <Route path={RoutePath.PreviousCycle} element={<CycleScreen isPrev />} />
            </>
          )}
          <Route path={RoutePath.Games} element={<GamesScreen />} />
          <Route path={RoutePath.Tickets} element={<TicketsScreen />} />
          <Route path={RoutePath.Points} element={<PointsScreen />} />
          <Route path={RoutePath.Policy} element={<PolicyScreen />} />
          {authUser?.role && ([UserRole.ADMIN].includes(authUser.role) || authUser.isRunner) && (
            <>
              <Route path={RoutePath.ActiveGames} element={<ActiveGamesScreen />} />
              <Route path={RoutePath.Game} element={<GameScreen />} />
            </>
          )}
          <Route path="*" element={<RootScreen />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default class AppRouter {
  static router = createBrowserRouter([
    {
      element: (
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
          }}
        >
          <AppRouterComponent />
        </QueryParamProvider>
      ),
      path: '*',
    },
  ]);
}
