import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import StorageContext from './StorageContext';
import StorageService from '../../services/storage';

interface StorageProviderProps {
  children: ReactNode;
}

export const StorageProvider = ({ children }: StorageProviderProps) => {
  const [, setStore] = useState<{ [key: string]: any }>(StorageService.storage);

  useEffect(() => {
    StorageService.setStore = setStore;
  }, []);

  const storageHas = useCallback((key: string) => StorageService.has(key), []);

  const storageGet = useCallback((key: string): any => StorageService.get(key), []);

  const storageSet = useCallback((key: string, value: any) => StorageService.set(key, value), []);

  const storageRemove = useCallback((...keys: string[]) => StorageService.remove(...keys), []);

  const storageClearAll = useCallback(() => StorageService.clearAll(), []);

  return (
    <StorageContext.Provider
      value={{
        storageHas,
        storageGet,
        storageSet,
        storageRemove,
        storageClearAll,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};
