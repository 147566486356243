import { useState } from 'react';
import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { apiFetchGames, apiFetchUsers } from '../../services/api';
import GameView from '../../components/GameView/GameView';
import { GetGamesDtoFilterEnum } from '@pokerrrr2/server/src/modules/games/games.dto';

export default function GamesScreen() {
  const [activeGamesFilter] = useState(GetGamesDtoFilterEnum.ActiveGames);

  const { data: activeGamesRes, refetch: refetchGames } = useQuery({
    queryKey: ['games', activeGamesFilter],
    queryFn: () => apiFetchGames({ filter: activeGamesFilter }),
    refetchInterval: 30_000,
  });

  const { data: usersRes } = useQuery({
    queryKey: ['users'],
    queryFn: () => apiFetchUsers(),
    refetchInterval: 30_000,
  });

  return (
    <Stack sx={{ flex: 1, p: 3, gap: 3 }}>
      {activeGamesRes?.data.map(game => {
        return <GameView key={game.oid} game={game} users={usersRes?.data} showDataTable={true} refreshData={refetchGames} />;
      })}
    </Stack>
  );
}
