import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { apiFetchGame, apiFetchUsers } from '../../services/api';
import { useParams } from 'react-router-dom';
import GameView from '../../components/GameView/GameView';

export default function GameScreen() {
  let { id } = useParams();

  const { data: gameRes, refetch: refetchGame } = useQuery({
    queryKey: ['games'],
    queryFn: () => apiFetchGame({ id: Number(id) }),
    refetchInterval: 30_000,
  });

  const { data: usersRes } = useQuery({
    queryKey: ['users'],
    queryFn: () => apiFetchUsers(),
    refetchInterval: 30_000,
  });

  return (
    <Stack sx={{ flex: 1, p: 3, gap: 3 }}>
      <GameView game={gameRes?.data} users={usersRes?.data} showDataTable={true} refreshData={refetchGame} />
    </Stack>
  );
}
