import React, { ReactNode, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import SearchField from '../SearchField/SearchField';
import { containMatch } from '../../utils/matchers';
import { find, isNumber, isString, take } from 'lodash';

export interface DropdownPropsOption {
  value?: string | null;
  label?: ReactNode;
}

export interface DropdownProps {
  value: string;
  onChange?: (value?: string | null) => void;
  label?: string;
  options?: Array<DropdownPropsOption>;
  hasSearch?: boolean;
  maxResults?: number;
}

export default function Dropdown({
  value,
  onChange: onChangeProp,
  label,
  options: optionsProp,
  hasSearch,
  maxResults = hasSearch ? 10 : optionsProp?.length || 0,
}: DropdownProps) {
  const [search, setSearch] = useState<string>('');

  const onChange = useCallback(
    (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
      let value: string | null | undefined = event.target.value as string;
      if (value === 'undefined') value = undefined;
      else if (value === 'null') value = null;
      onChangeProp?.(value);
    },
    [onChangeProp],
  );

  const onClose = useCallback((event: SyntheticEvent) => {
    setSearch('');
  }, []);

  const selectedOption = useMemo(() => find(optionsProp, { value }), [value, optionsProp]);

  const val = useMemo(() => (!optionsProp?.length ? '' : value), [value, optionsProp]);

  const options = useMemo(
    () =>
      take(
        optionsProp?.filter(o => {
          const value = isString(o.value) || isNumber(o.value) ? String(o.value) : '';
          const label = isString(o.label) || isNumber(o.label) ? String(o.label) : '';
          return containMatch(search, [value, label]);
        }) || [],
        maxResults,
      ),
    [maxResults, optionsProp, search],
  );

  return (
    <FormControl sx={{ flex: 1, width: '100%' }} size="small">
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={val}
        label={label}
        onChange={onChange}
        onClose={onClose}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '& .MuiPaper-root': {},
            '& .MuiPaper-root .MuiList-root': {},
          },
          disableAutoFocusItem: true,
        }}
      >
        <MenuItem value={String(selectedOption?.value || val)} sx={{ display: 'none' }}>
          {selectedOption?.label}
        </MenuItem>
        {hasSearch && (
          <Box px={1} pb={1}>
            <SearchField value={search} onChange={setSearch} sx={{ width: '100%' }} />
          </Box>
        )}
        {options?.map(option => (
          <MenuItem key={String(option.value)} value={String(option.value)}>
            {option.label}
          </MenuItem>
        ))}
        {hasSearch && !options.length && (
          <Box p={3} mt="5px" textAlign="center">
            No Results
          </Box>
        )}
      </Select>
    </FormControl>
  );
}
