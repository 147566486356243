import React, { cloneElement, ReactElement, useMemo } from 'react';
import { ButtonProps, Menu, MenuProps } from '@mui/material';

export interface MenuButtonProps {
  trigger: ReactElement<ButtonProps>;
  children?: MenuProps['children'];
}

function MenuButton({ trigger: triggerProp, children: childrenProp }: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const trigger = useMemo(() => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      triggerProp.props.onClick?.(event);
    };
    return cloneElement(triggerProp, { onClick });
  }, [triggerProp]);

  return (
    <div>
      {trigger}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 15,
                width: 10,
                height: 10,
                backgroundColor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
      >
        {childrenProp}
      </Menu>
    </div>
  );
}

export default MenuButton;
