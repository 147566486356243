import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { Game } from '@pokerrrr2/server/src/interfaces/game.interface';
import { ClubGameRankedPlayer } from '@pokerrrr2/server/src/interfaces/club.interface';
import { User } from '@pokerrrr2/server/src/interfaces/user.interface';
import { cloneDeep, keyBy, sumBy } from 'lodash';
import { getUserNameTag } from '../../utils/users.util';

export interface UpdateGameRankingDialogProps {
  onRequestClose: () => void;
  game?: Game;
  users?: User[];
  onSave?: (data: { id: number; ranking: ClubGameRankedPlayer[] }) => void;
}

export default function UpdateGameRankingDialog({ onRequestClose, game, users, onSave }: UpdateGameRankingDialogProps) {
  const [ranking, setRanking] = useState<ClubGameRankedPlayer[] | undefined>(game?.clubGame?.ranking);

  const usersCodeMap = useMemo(() => keyBy(users || [], 'code'), [users]);

  useEffect(() => {
    setRanking(game?.clubGame?.ranking);
  }, [game?.clubGame?.ranking]);

  const handleCancel = () => {
    onRequestClose();
  };

  const handleSave = async () => {
    const parsedRanking =
      ranking?.map(rank => {
        rank.diff = Number(rank.diff);
        return rank;
      }) || [];
    onSave?.({ id: game!.id, ranking: parsedRanking });
    onRequestClose();
  };

  const renderRanking = useMemo(
    () =>
      game?.clubGame?.ranking?.map((rank, index) => {
        if (!ranking?.[index]) return null;

        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const rankingClone = cloneDeep(ranking!);
          rankingClone[index].diff = event.target.value as unknown as number;
          setRanking(rankingClone);
        };

        return (
          <Grid key={rank.playerCode} container spacing={2} mb={1} alignItems="center">
            <Grid item xs={1}>{`l ❂${index + 1}`}</Grid>
            <Grid item xs={4}>
              {getUserNameTag(usersCodeMap[rank.playerCode])}
            </Grid>
            <Grid item xs={2}>
              {rank.buyIn}
            </Grid>
            <Grid item xs={2}>
              {rank.diff}
            </Grid>
            <Grid item xs={3}>
              <TextField fullWidth size="small" type="number" value={ranking[index].diff} onChange={onChange} />
            </Grid>
          </Grid>
        );
      }),
    [game?.clubGame?.ranking, usersCodeMap, ranking, setRanking],
  );

  const canSave = useMemo(
    () => sumBy(game?.clubGame?.ranking, 'diff') === sumBy(ranking, rank => Number(rank.diff)),
    [game?.clubGame?.ranking, ranking],
  );

  return (
    <Dialog
      maxWidth="xl"
      TransitionProps={{
        onEntered: () => {},
      }}
      open={Boolean(game)}
    >
      <Box sx={{ width: '350px' }} />
      <DialogTitle>Update Game Ranking</DialogTitle>
      <DialogContent dividers sx={{ whiteSpace: 'pre-wrap', overflowY: 'auto' }}>
        <Box sx={{ minWidth: '650px' }}>
          <Grid container spacing={2} mb={1} alignItems="center">
            <Grid item xs={1}>
              Place
            </Grid>
            <Grid item xs={4}>
              Name
            </Grid>
            <Grid item xs={2}>
              BuyIn
            </Grid>
            <Grid item xs={2}>
              Profit
            </Grid>
            <Grid item xs={3}>
              New Profit
            </Grid>
          </Grid>
          {renderRanking}
        </Box>
      </DialogContent>
      <DialogActions>
        {!canSave && (
          <Typography variant="subtitle2" color="darkred" mx={2}>
            Total sum need to be equal to original amount
          </Typography>
        )}
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
