import { ClubGameRankedPlayer } from './club.interface';
import { SupportedGameType } from './game.interface';
import { cloneDeep, sum } from 'lodash';
import { roundPrecision } from '../utils/numbers';

export const SYSTEM_PLAYER_NAME = 'The System';
export const SYSTEM_PLAYER_CODE = '1XXAB';
export const SYSTEM_PLAYER_ID = '6506fbcbe98a53b18c331e08';
export const MANAGERS_PLAYERS_IDS = ['6400f64aa1940fc360c0a388', SYSTEM_PLAYER_ID];

export enum UserRole {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  PLAYER = 'PLAYER',
}

export const AllUserRoleValues = Object.values(UserRole);

export enum UserInGameRole {
  OWNER = 'owner',
  MANAGER = 'manager',
  MEMBER = 'member',
}

export const AllUserInGameRoleValues = Object.values(UserInGameRole);

export interface UserSatellite {
  gameOid: string;
  gameCode: string;
  gameType: SupportedGameType;
  createdAt: Date;
  prize: number | null;
  onlineGameType: SupportedGameType | null;
  usedAt: Date | true | null;
}

export interface UserRake {
  total: number;
  club: number;
  agent: number;
  personal: number;
}

export const getDefaultUserRake = (): UserRake => ({
  total: 0,
  club: 0,
  agent: 0,
  personal: 0,
});

export interface UserCycle {
  balance: number;
  rakeBack: number;
  rake: UserRake;
  mmtPoints?: number;
}

export const getDefaultUserCycle = (): UserCycle => ({
  balance: 0,
  rakeBack: 0,
  rake: getDefaultUserRake(),
  mmtPoints: 0,
});

export interface User {
  id: number;

  name?: string | null;
  phoneNumber?: string | null;
  password?: string | null;
  code: string;
  inGameId: string;
  inGameName: string;
  inGameRole: UserInGameRole;
  balance: number;
  cup: number;

  liveRanking: { [gameId: string]: ClubGameRankedPlayer };
  satellites: UserSatellite[];
  mmtPoints: number;

  personalRakePercentage: number;
  rakePercentage: number;
  rakeBack: number;

  role: UserRole;
  agentId?: number | null;
  agent?: User;
  players?: User[];

  inPasswordResetProcess: boolean;
  isRunner: boolean;
  isAccountant: boolean;

  rake: UserRake;
  cycle: UserCycle;

  createdAt: Date;
  updatedAt: Date;
}

export type UserRef = Pick<User, 'id' | 'code' | 'inGameName' | 'name'>;
export const USER_REF_FIELDS: Array<keyof User> = ['id', 'code', 'inGameName', 'name'];

export function getPlayerLiveBalanceAndSatellites(balance: User['balance'], liveRanking: User['liveRanking'], satellites: User['satellites']) {
  const satellitesClone = cloneDeep(satellites);
  const liveDiffSum = sum(Object.values(liveRanking).map(rank => rank.diff));
  let liveTicketsWorthSum = 0;
  Object.values(liveRanking).forEach(playerRank => {
    let usedTickets = 0;
    satellitesClone.forEach(satelliteTicket => {
      const buyInCount = (playerRank.bought || 0) + (playerRank.addon || 0);
      if (!satelliteTicket.usedAt && satelliteTicket.onlineGameType === playerRank.supportedGameType && usedTickets < buyInCount) {
        satelliteTicket.usedAt = true;
        usedTickets += 1;
      }
    });
    liveTicketsWorthSum += (playerRank.ticketPrice || 0) * usedTickets;
  });

  return {
    liveBalance: roundPrecision(balance + liveTicketsWorthSum + liveDiffSum),
    liveSatellites: satellitesClone,
  };
}
