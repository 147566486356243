import React, { ReactNode, useId, useState } from 'react';
import { IconButton, IconButtonProps, Menu, MenuItem, Typography } from '@mui/material';

export interface IconButtonMenuItem {
  label: string;
  onClick: () => unknown;
}

export interface IconButtonMenuProps<T extends IconButtonMenuItem> {
  icon: IconButtonProps['children'];
  filter: (i: T) => boolean;
  items: T[];
  header?: ReactNode;
}

function IconButtonMenu<T extends IconButtonMenuItem>({ icon, filter = i => true, items, header }: IconButtonMenuProps<T>) {
  const componentId = useId();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const filteredItems = items.filter(filter);

  if (!filteredItems.length) return null;

  return (
    <>
      <IconButton size="large" aria-controls={componentId} aria-haspopup="true" onClick={handleOpenUserMenu} color="inherit">
        {icon}
      </IconButton>

      <Menu
        sx={{ mt: '45px' }}
        id={componentId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseUserMenu}
      >
        {header}
        {filteredItems.map(item => (
          <MenuItem
            key={item.label}
            onClick={() => {
              handleCloseUserMenu();
              item.onClick();
            }}
          >
            <Typography textAlign="center">{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default IconButtonMenu;
