import { useCallback, useMemo } from 'react';
import { Card, Box, Stack, Typography, IconButton, Tooltip } from '@mui/material';
import { Game, GameAction, SupportedGameTypeName } from '@pokerrrr2/server/src/interfaces/game.interface';
import DataTable from '../../components/DataTable/DataTable';
import { SxProps, Theme } from '@mui/material';
import { ClubGameStatus } from '@pokerrrr2/server/src/interfaces/club.interface';
import StartIcon from '@mui/icons-material/Start';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useConfirmDialogMutation } from '../../hooks/useConfirmDialogMutation';
import { apiGameAction } from '../../services/api';
import useSnackbar from '../../providers/snackbar/useSnackbar';
import { get } from 'lodash';
import useAuth from '../../providers/auth/useAuth';
import { User, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { currency } from '../../utils/money';
import { GetGameUsersTableColumns } from '../../routes/games/GamesScreen.utils';
import { toTime } from '../../utils/date';
import { GetPosOnEllipse } from '../../utils/view.util';

export interface GameViewProps {
  game?: Game;
  users?: User[];
  showDataTable?: Boolean;
  refreshData?: () => {};
}

export default function GameView({ game, users, showDataTable, refreshData }: GameViewProps) {
  const { authUser } = useAuth();
  const { setSnackbar } = useSnackbar();

  const columns = useMemo(() => GetGameUsersTableColumns(authUser, users), [authUser, users]);

  const { mutate: gameAction, renderConfirmDialogMutation: gameActionConfirmDialog } = useConfirmDialogMutation({
    text: 'This will apply the action.',
    mutationFn: apiGameAction,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
      if (refreshData) {
        await refreshData();
      }
    },
    onSuccess: async res => {
      setSnackbar({ children: `Action successfully applied to game ${res.data.code}`, severity: 'success' });
      if (refreshData) {
        await refreshData();
      }
    },
  });

  const onStartClick = useCallback(
    () => game && gameAction({ oid: game?.oid, action: GameAction.Start }, { text: 'This will manually start the game.' }),
    [game, gameAction],
  );

  const onPauseClick = useCallback(
    () => game && gameAction({ oid: game?.oid, action: GameAction.Pause }, { text: 'This will manually pause the game.' }),
    [game, gameAction],
  );

  const onResumeClick = useCallback(
    () => game && gameAction({ oid: game?.oid, action: GameAction.Resume }, { text: 'This will manually resume the game.' }),
    [game, gameAction],
  );

  const onTerminateClick = useCallback(
    () => game && gameAction({ oid: game?.oid, action: GameAction.Terminate }, { text: 'This will manually terminate the game.' }),
    [game, gameAction],
  );

  const title = game?.supportedGameType ? SupportedGameTypeName[game?.supportedGameType] : 'Unknown';
  const gameStatus = game?.status || 'NA';
  const gameElaspedTime = game?.clubGame.elaspedTime ? toTime(game?.clubGame.elaspedTime) : 'NA';
  const gameRake = [UserRole.ADMIN].includes(authUser.role) ? currency(game?.raking.club) || 'NA' : 'NA';

  return (
    <Stack sx={styles.rootContainer}>
      <Box sx={styles.tableContainer}>
        <Typography variant="h6" sx={styles.title}>
          {title}
        </Typography>
        <Box sx={styles.actionsContainer}>
          {game && [ClubGameStatus.PREPARE].includes(game.status) && (
            <Tooltip title="Start" arrow>
              <IconButton color="primary" onClick={onStartClick}>
                <StartIcon sx={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          )}
          {game && [ClubGameStatus.PLAYING, ClubGameStatus.WAITING].includes(game.status) && (
            <Tooltip title="Pause" arrow>
              <IconButton color="primary" onClick={onPauseClick}>
                <PauseIcon />
              </IconButton>
            </Tooltip>
          )}
          {game && [ClubGameStatus.PAUSE].includes(game.status) && (
            <Tooltip title="Resume" arrow>
              <IconButton color="primary" onClick={onResumeClick}>
                <PlayArrowIcon sx={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          )}
          {game && ![ClubGameStatus.END].includes(game.status) && (
            <Tooltip title="Terminate" arrow>
              <IconButton color="primary" onClick={onTerminateClick}>
                <PowerSettingsNewIcon sx={{ color: 'darkRed' }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box sx={styles.tablePlayersContainer}>
          <Card sx={[styles.tablePlayer, { left: stylesConstants.tableWidth / 2, top: stylesConstants.tableHeight / 2 }]}>
            <Typography sx={styles.tablePlayerInfo}>{gameStatus}</Typography>
            <Typography sx={styles.tablePlayerInfo}>{gameElaspedTime}</Typography>
            {[UserRole.ADMIN].includes(authUser.role) && <Typography sx={styles.tablePlayerInfo}>{gameRake}</Typography>}
          </Card>
          {game?.clubGame.players.map(player => {
            const playerRanking = game?.clubGame.ranking.find(rank => rank.playerCode === player.playerCode);
            const [x, y] = getPlayerPositionInTable(game?.clubGame.players.length, player.seat);

            const playerDisplayName = player?.displayName || '';
            const playerDiff = currency(playerRanking?.diff) || '';

            return (
              <Card key={player.seat} sx={[styles.tablePlayer, { left: x, top: y }]}>
                <Typography sx={styles.tablePlayerInfo}>{playerDisplayName}</Typography>
                {[UserRole.ADMIN].includes(authUser.role) && <Typography sx={styles.tablePlayerInfo}>{playerDiff}</Typography>}
              </Card>
            );
          })}
        </Box>
      </Box>
      {showDataTable && (
        <Box sx={styles.dataTableContainer}>
          <DataTable
            columns={columns}
            rows={game?.clubGame.ranking || []}
            paginationModel={{ pageSize: 5 }}
            sortModel={[{ field: 'diff', sort: 'desc' }]}
            getRowId={row => row.playerCode}
          />
        </Box>
      )}
      {gameActionConfirmDialog()}
    </Stack>
  );
}

function getPlayerPositionInTable(numOfPlayers: number, seat: number) {
  return GetPosOnEllipse(
    numOfPlayers,
    seat,
    stylesConstants.tableWidth / 2 - stylesConstants.tablePlayerWidth / 2,
    stylesConstants.tableHeight / 2 - 40 / 2,
    stylesConstants.tableWidth / 2,
    stylesConstants.tableHeight / 2,
    0,
    0,
  );
}

const stylesConstants = {
  tableWidth: 400,
  tableHeight: 280,
  tablePlayerWidth: 80,
};

const styles = {
  rootContainer: {
    p: 1,
    gap: 2,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  tableContainer: {
    width: stylesConstants.tableWidth,
  },
  title: {
    flex: 1,
    textAlign: 'center',
  },
  tablePlayersContainer: {
    position: 'relative',
    width: stylesConstants.tableWidth,
    height: stylesConstants.tableHeight,
  },
  tablePlayer: {
    position: 'absolute',
    width: stylesConstants.tablePlayerWidth - 2,
    border: '1px solid',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
  },
  tablePlayerInfo: {
    fontSize: 11,
    flex: '1 1 auto',
    textAlign: 'center',
    height: 20,
    borderBottom: '1px dotted',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:first-child': {
      fontWeight: 'bold',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  dataTableContainer: {
    minWidth: stylesConstants.tableWidth,
    flex: `1 1 ${stylesConstants.tableWidth}px`,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 1,
  },
} satisfies Record<string, SxProps<Theme>>;
