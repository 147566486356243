import React, { useCallback, useMemo } from 'react';
import { UserRef } from '@pokerrrr2/server/src/interfaces/user.interface';
import Dropdown, { DropdownProps } from './Dropdown';
import { getUserNameTag } from '../../utils/users.util';
import { orderBy } from 'lodash';

interface UsersDropdownProps {
  value?: number | null;
  onChange?: (value: number | null) => void;
  label?: string;
  users?: UserRef[];
  inNullable?: boolean;
}

export default function UsersDropdown({ value: valueProp, onChange: onChangeProp, label = 'User', users, inNullable }: UsersDropdownProps) {
  const options = useMemo(
    (): DropdownProps['options'] => [
      ...(inNullable
        ? [
            {
              value: null,
              label: <em>None</em>,
            },
          ]
        : []),
      ...orderBy(
        (users || []).map(user => ({
          value: String(user.id),
          label: getUserNameTag(user),
        })),
        i => i.label.toLowerCase(),
      ),
    ],
    [users, inNullable],
  );

  const onChange = useCallback(
    (value?: string | null) => {
      if (value !== undefined && value !== null) onChangeProp?.(Number(value));
      else onChangeProp?.(value || null);
    },
    [onChangeProp],
  );

  const value = valueProp === undefined || valueProp === null ? '' : String(valueProp);
  return <Dropdown value={value} onChange={onChange} options={options} label={label} hasSearch />;
}
