import { User } from '@pokerrrr2/server/src/interfaces/user.interface';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { filter, sumBy } from 'lodash';
import { currency } from '../../utils/money';
import { roundPrecision } from '@pokerrrr2/server/src/utils/numbers';

export const getCycleTableColumns = (authUser: User, rows: User[], isPrev?: boolean): GridColDef[] => {
  return filter([
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'name',
      headerName: 'שם',
      width: 150,
      editable: true,
    },
    {
      field: 'inGameName',
      headerName: 'שם באפליקציה',
      width: 150,
    },
    {
      field: 'code',
      headerName: 'קוד שחקן',
      width: 100,
    },
    {
      field: 'balance',
      headerName: `${currency(roundPrecision(sumBy(rows, isPrev ? 'cycle.balance' : 'balance')))} | באלנס`,
      width: 300,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const n1 = Number(v1?.split(' ')?.[0]?.replace(',', '') || 0);
        const n2 = Number(v2?.split(' ')?.[0]?.replace(',', '') || 0);
        return n1 === n2 ? 0 : n1 === 0 ? -1 : n2 === 0 ? 1 : n1 < n2 ? -1 : 1;
      },
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { balance, cycle } = row;
        return currency(isPrev ? cycle.balance : balance, { withSymbol: false });
      },
    },
    {
      field: 'rakeBack',
      headerName: `${currency(roundPrecision(sumBy(rows, isPrev ? 'cycle.rakeBack' : 'rakeBack')))} | רייקבק`,
      width: 300,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const n1 = Number(v1?.split(' ')?.[0]?.replace(',', '') || 0);
        const n2 = Number(v2?.split(' ')?.[0]?.replace(',', '') || 0);
        return n1 === n2 ? 0 : n1 === 0 ? -1 : n2 === 0 ? 1 : n1 < n2 ? -1 : 1;
      },
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rakeBack, cycle } = row;
        return currency(isPrev ? cycle.rakeBack : rakeBack, { withSymbol: false });
      },
    },
    {
      field: 'total',
      headerName: `${currency(
        roundPrecision(sumBy(rows, isPrev ? 'cycle.balance' : 'balance') + sumBy(rows, isPrev ? 'cycle.rakeBack' : 'rakeBack')),
      )} | טוטאל`,
      width: 300,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const n1 = Number(v1?.split(' ')?.[0]?.replace(',', '') || 0);
        const n2 = Number(v2?.split(' ')?.[0]?.replace(',', '') || 0);
        return n1 === n2 ? 0 : n1 === 0 ? -1 : n2 === 0 ? 1 : n1 < n2 ? -1 : 1;
      },
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { balance, rakeBack, cycle } = row;
        return currency(roundPrecision(isPrev ? cycle.balance + cycle.rakeBack : balance + rakeBack), { withSymbol: false });
      },
    },
    {
      field: 'rakePersonal',
      headerName: 'רייק שיצר לשחקן בחזרה',
      width: 190,
      type: 'number',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rake, cycle } = row;
        return currency(roundPrecision(isPrev ? cycle.rake.personal : rake.personal), { withSymbol: false });
      },
    },
    {
      field: 'rakeAgent',
      headerName: 'רייק שיצר לסוכן',
      width: 150,
      type: 'number',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rake, cycle } = row;
        return currency(roundPrecision(isPrev ? cycle.rake.agent : rake.agent), { withSymbol: false });
      },
    },
    {
      field: 'rakeClub',
      headerName: 'רייק שיצר למועדון',
      width: 150,
      type: 'number',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rake, cycle } = row;
        return currency(roundPrecision(isPrev ? cycle.rake.club : rake.club), { withSymbol: false });
      },
    },
    {
      field: 'rakeTotal',
      headerName: 'רייק שיצר טוטאל',
      width: 150,
      type: 'number',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rake, cycle } = row;
        return currency(roundPrecision(isPrev ? cycle.rake.total : rake.total), { withSymbol: false });
      },
    },
  ] as GridColDef[]);
};
