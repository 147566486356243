import { ClubGame, ClubGameStatus } from './club.interface';
import { UserRef } from './user.interface';

export enum GameAction {
  Start = 'start',
  Pause = 'pause',
  Resume = 'resume',
  Terminate = 'terminate',
}

export interface GameRaking {
  total: number;
  club: number;
  agents: { [code: string]: number };
  players: { [code: string]: number };
}

export const getDefaultGameRaking = (): GameRaking => ({
  total: 0,
  club: 0,
  agents: {},
  players: {},
});

export interface GameSatellite {
  gap: number;
  max: number | null;
  prize: number;
  onlineGameType: SupportedGameType | null;
}

export interface GameMMTPoints {
  [playerCode: string]: number;
}

export interface Game {
  id: number;

  supportedGameType: SupportedGameType | null;
  oid: string;
  code: string;
  status: ClubGameStatus;
  clubGame: ClubGame;
  raking: GameRaking;
  rankedPlayersCodes: string[];
  rankedPlayersAgentsCodes: string[];
  satellite: GameSatellite | null;
  preRegPlayersCodes: string[];
  mmtPoints: GameMMTPoints;

  openedById?: number | null;
  openedBy?: UserRef;
  closedById?: number | null;
  closedBy?: UserRef;

  createdAt: Date;
  updatedAt: Date;
}

export enum SupportedGameType {
  CashHoldemHalfOne6p = 'cash-holdem-half-one-6p',
  CashHoldemHalfOne9p = 'cash-holdem-half-one-9p',
  CashHoldemOneTwo6p = 'cash-holdem-one-two-6p',
  CashHoldemOneTwo8p = 'cash-holdem-one-two-8p',
  CashHoldemOneTwo9p = 'cash-holdem-one-two-9p',
  CashHoldemOneTwo9pRI2 = 'cash-holdem-one-two-9p-ri2',
  CashHoldemOneHalfThree6p = 'cash-holdem-one-half-three-6p',
  CashHoldemOneHalfThree8p = 'cash-holdem-one-half-three-8p',
  CashHoldemOneHalfThree9p = 'cash-holdem-one-half-three-9p',
  CashHoldemTwoFour6p = 'cash-holdem-two-four-6p',
  CashHoldemTwoFour9p = 'cash-holdem-two-four-9p',
  CashOmaha4OneTwo6p = 'cash-omaha4-one-two-6p',
  CashOmaha4OneTwo6pRI2 = 'cash-omaha4-one-two-6p-ri2',
  CashOmaha4OneTwo8p = 'cash-omaha4-one-two-8p',
  CashOmaha4OneTwo9p = 'cash-omaha4-one-two-9p',
  CashOmaha4OneHalfThree6p = 'cash-omaha4-one-half-three-6p',
  CashOmaha4OneHalfThree8p = 'cash-omaha4-one-half-three-8p',
  CashOmaha4OneHalfThree9p = 'cash-omaha4-one-half-three-9p',
  CashOmaha4TwoFour6p = 'cash-omaha4-two-four-6p',
  CashOmaha4TwoFour9p = 'cash-omaha4-two-four-9p',
  CashOmaha5OneTwo6p = 'cash-omaha5-one-two-6p',
  CashOmaha5OneTwo6pRI2 = 'cash-omaha5-one-two-6p-ri2',
  CashOmaha5OneTwo8p = 'cash-omaha5-one-two-8p',
  CashOmaha5OneTwo9p = 'cash-omaha5-one-two-9p',
  CashOmaha5OneHalfThree6p = 'cash-omaha5-one-half-three-6p',
  CashOmaha5OneHalfThree8p = 'cash-omaha5-one-half-three-8p',
  CashOmaha5OneHalfThree9p = 'cash-omaha5-one-half-three-9p',
  CashOmaha5TwoFour6p = 'cash-omaha5-two-four-6p',
  CashOmaha5TwoFour9p = 'cash-omaha5-two-four-9p',
  CashOmaha6HalfOne6p = 'cash-omaha6-half-one-6p',
  CashOmaha6OneTwo6p = 'cash-omaha6-one-two-6p',
  CashOmaha6OneTwo6pRI2 = 'cash-omaha6-one-two-6p-ri2',
  CashOmaha6OneHalfThree6p = 'cash-omaha6-one-half-three-6p',
  CashOmaha6TwoFour6p = 'cash-omaha6-two-four-6p',

  CashROENLHOmaha4HalfOne6p = 'cash-roe-nlh-omaha4-half-one-6p',
  CashROENLHOmaha4HalfOne8p = 'cash-roe-nlh-omaha4-half-one-8p',
  CashROENLHOmaha4HalfOne9p = 'cash-roe-nlh-omaha4-half-one-9p',
  CashROENLHOmaha4OneTwo6p = 'cash-roe-nlh-omaha4-one-two-6p',
  CashROENLHOmaha4OneTwo6pRI2 = 'cash-roe-nlh-omaha4-one-two-6p-ri2',
  CashROENLHOmaha4OneTwo8p = 'cash-roe-nlh-omaha4-one-two-8p',
  CashROENLHOmaha4OneTwo9p = 'cash-roe-nlh-omaha4-one-two-9p',
  CashROENLHOmaha4OneHalfThree6p = 'cash-roe-nlh-omaha4-one-half-three-6p',
  CashROENLHOmaha4OneHalfThree8p = 'cash-roe-nlh-omaha4-one-half-three-8p',
  CashROENLHOmaha4OneHalfThree9p = 'cash-roe-nlh-omaha4-one-half-three-9p',
  CashROENLHOmaha4TwoFour6p = 'cash-roe-nlh-omaha4-two-four-6p',
  CashROENLHOmaha4TwoFour9p = 'cash-roe-nlh-omaha4-two-four-9p',
  CashROEOmaha456HalfOne6p = 'cash-roe-omaha456-half-one-6p',
  CashROEOmaha456OneTwo6p = 'cash-roe-omaha456-one-two-6p',
  CashROEOmaha456OneTwo6pRI2 = 'cash-roe-omaha456-one-two-6p-ri2',
  CashROEOmaha456OneHalfThree6p = 'cash-roe-omaha456-one-half-three-6p',
  CashROEOmaha456TwoFour6p = 'cash-roe-omaha456-two-four-6p',

  CashOFCRegularHalfOne3p = 'cash-ofc-regular-half-one-3p',
  CashOFCRegularOneTwo3p = 'cash-ofc-regular-one-two-3p',
  CashOFCRegularOneHalfThree3p = 'cash-ofc-regular-one-half-three-3p',
  CashOFCRegularTwoFour3p = 'cash-ofc-regular-two-four-3p',
  CashOFCProgressiveHalfOne3p = 'cash-ofc-progressive-half-one-3p',
  CashOFCProgressiveOneTwo3p = 'cash-ofc-progressive-one-two-3p',
  CashOFCProgressiveOneHalfThree3p = 'cash-ofc-progressive-one-half-three-3p',
  CashOFCProgressiveTwoFour3p = 'cash-ofc-progressive-two-four-3p',

  MMTMainFreeRollNLH = 'mmt-main-free-roll-nlh',
  MMTMainFreeRollPLO4 = 'mmt-main-free-roll-plo4',
  MMTMainFreeRollPLO5 = 'mmt-main-free-roll-plo5',
  MMTMainFreeRollPLO6 = 'mmt-main-free-roll-plo6',

  MMTCrazyRiverNLHAddOn = 'mmt-crazy-river-nlh-add-on',
  MMTDonkeyKickNLHRebuy = 'mmt-donkey-kick-nlh-rebuy',
  MMTBonnanza150NLHRebuy = 'mmt-bonnanza-nlh-rebuy',
  MMTKongfuPandaNLHDeep200Re = 'mmt-kongfu-panda-nlh-deep',
  MMTSniperShotNLHFreezeout250 = 'mmt-sniper-shot-nlh-freezeout',
  MMTCoconutBountyNLH = 'mmt-coconut-bounty-nlh',
  MMTTerminatorBountyNLH = 'mmt-terminator-bounty-nlh',
  MMTDesperadoBountyNLH = 'mmt-desperado-bounty-nlh',
  MMTLucky777BountyNLH = 'mmt-lucky-777-bounty-nlh',
  MMTDuckHuntingNLH = 'mmt-duck-hunting-nlh',

  MMTBoiKalaNLH = 'mmt-boi-kala-nlh',
  MMTRelaxationNLH = 'mmt-relaxation-nlh',
  MMTAfterParty = 'mmt-after-party',
  MMTMidnightMadnessBounty7030 = 'mmt-midnight-madness-bounty',

  MMTCenturyNLHRebuy = 'mmt-century-nlh-rebuy',
  MMTOmahaFeverBountyPLO4 = 'mmt-omaha-fever-plo4',
  MMT50CentBountyNLH5050 = 'mmt-50-cent-bounty-nlh',
  MMTPacmanBountyNLH5025 = 'mmt-pacman-bounty-nlh',
  MMTPacmanBountyPLO45025 = 'mmt-pacman-bounty-plo4',
  MMTRainbowBountyPLO5 = 'mmt-rainbow-bounty-plo5',

  MMTPyromaniaDeepStackNLH30020 = 'mmt-pyromania-deep-stack-nlh',
  MMTWildWestBountyNLH30020 = 'mmt-wild-west-bounty-nlh',

  MMTChampionsNLH = 'mmt-champions-nlh',
  MMTLucky777NLH = 'mmt-lucky-777-nlh',
  MMTLucky777PLO4 = 'mmt-lucky-777-plo4',
  MMTFullTiltNLH404 = 'mmt-full-tilt-nlh',
  MMTFullTiltPLO5404 = 'mmt-full-tilt-plo5',

  MMTSatelliteNLH1205 = 'mmt-satellite-nlh-120-5',
  MMTSatelliteNLH1005 = 'mmt-satellite-nlh-100-5',
  MMTSatelliteNLH605 = 'mmt-satellite-nlh-60-5',
  MMTSatelliteNLH505 = 'mmt-satellite-nlh-50-5',
  MMTSatelliteNLH303 = 'mmt-satellite-nlh-30-3',
}

export const OFFLINE_GAME_TYPE = 'offline-game';
export const OFFLINE_GAME_TYPE_NAME = '--Offline Game--';

export type SupportedGameTypeWithOfflineType = SupportedGameType | typeof OFFLINE_GAME_TYPE;

export const SupportedGameTypeName = {
  [SupportedGameType.CashHoldemHalfOne6p]: 'Cash Holdem 0.5/1 6p',
  [SupportedGameType.CashHoldemHalfOne9p]: 'Cash Holdem 0.5/1 9p',
  [SupportedGameType.CashHoldemOneTwo6p]: 'Cash Holdem 1/2 6p',
  [SupportedGameType.CashHoldemOneTwo8p]: 'Cash Holdem 1/2 8p',
  [SupportedGameType.CashHoldemOneTwo9p]: 'Cash Holdem 1/2 9p',
  [SupportedGameType.CashHoldemOneTwo9pRI2]: 'Cash Holdem 1/2 9p RI2',
  [SupportedGameType.CashHoldemOneHalfThree6p]: 'Cash Holdem 1.5/3 6p',
  [SupportedGameType.CashHoldemOneHalfThree8p]: 'Cash Holdem 1.5/3 8p',
  [SupportedGameType.CashHoldemOneHalfThree9p]: 'Cash Holdem 1.5/3 9p',
  [SupportedGameType.CashHoldemTwoFour6p]: 'Cash Holdem 2/4 6p',
  [SupportedGameType.CashHoldemTwoFour9p]: 'Cash Holdem 2/4 9p',
  [SupportedGameType.CashOmaha4OneTwo6p]: 'Cash Omaha4 1/2 6p',
  [SupportedGameType.CashOmaha4OneTwo6pRI2]: 'Cash Omaha4 1/2 6p RI2',
  [SupportedGameType.CashOmaha4OneTwo8p]: 'Cash Omaha4 1/2 8p',
  [SupportedGameType.CashOmaha4OneTwo9p]: 'Cash Omaha4 1/2 9p',
  [SupportedGameType.CashOmaha4OneHalfThree6p]: 'Cash Omaha4 1.5/3 6p',
  [SupportedGameType.CashOmaha4OneHalfThree8p]: 'Cash Omaha4 1.5/3 8p',
  [SupportedGameType.CashOmaha4OneHalfThree9p]: 'Cash Omaha4 1.5/3 9p',
  [SupportedGameType.CashOmaha4TwoFour6p]: 'Cash Omaha4 2/4 6p',
  [SupportedGameType.CashOmaha4TwoFour9p]: 'Cash Omaha4 2/4 9p',
  [SupportedGameType.CashOmaha5OneTwo6p]: 'Cash Omaha5 1/2 6p',
  [SupportedGameType.CashOmaha5OneTwo6pRI2]: 'Cash Omaha5 1/2 6p RI2',
  [SupportedGameType.CashOmaha5OneTwo8p]: 'Cash Omaha5 1/2 8p',
  [SupportedGameType.CashOmaha5OneTwo9p]: 'Cash Omaha5 1/2 9p',
  [SupportedGameType.CashOmaha5OneHalfThree6p]: 'Cash Omaha5 1.5/3 6p',
  [SupportedGameType.CashOmaha5OneHalfThree8p]: 'Cash Omaha5 1.5/3 8p',
  [SupportedGameType.CashOmaha5OneHalfThree9p]: 'Cash Omaha5 1.5/3 9p',
  [SupportedGameType.CashOmaha5TwoFour6p]: 'Cash Omaha5 2/4 6p',
  [SupportedGameType.CashOmaha5TwoFour9p]: 'Cash Omaha5 2/4 9p',
  [SupportedGameType.CashOmaha6HalfOne6p]: 'Cash Omaha6 0.5/1 6p',
  [SupportedGameType.CashOmaha6OneTwo6p]: 'Cash Omaha6 1/2 6p',
  [SupportedGameType.CashOmaha6OneTwo6pRI2]: 'Cash Omaha6 1/2 6p RI2',
  [SupportedGameType.CashOmaha6OneHalfThree6p]: 'Cash Omaha6 1.5/3 6p',
  [SupportedGameType.CashOmaha6TwoFour6p]: 'Cash Omaha6 2/4 6p',

  [SupportedGameType.CashROENLHOmaha4HalfOne6p]: 'Cash ROE NLH-Omaha4 0.5/1 6p',
  [SupportedGameType.CashROENLHOmaha4HalfOne8p]: 'Cash ROE NLH-Omaha4 0.5/1 8p',
  [SupportedGameType.CashROENLHOmaha4HalfOne9p]: 'Cash ROE NLH-Omaha4 0.5/1 9p',
  [SupportedGameType.CashROENLHOmaha4OneTwo6p]: 'Cash ROE NLH-Omaha4 1/2 6p',
  [SupportedGameType.CashROENLHOmaha4OneTwo6pRI2]: 'Cash ROE NLH-Omaha4 1/2 6p RI2',
  [SupportedGameType.CashROENLHOmaha4OneTwo8p]: 'Cash ROE NLH-Omaha4 1/2 8p',
  [SupportedGameType.CashROENLHOmaha4OneTwo9p]: 'Cash ROE NLH-Omaha4 1/2 9p',
  [SupportedGameType.CashROENLHOmaha4OneHalfThree6p]: 'Cash ROE NLH-Omaha4 1.5/3 6p',
  [SupportedGameType.CashROENLHOmaha4OneHalfThree8p]: 'Cash ROE NLH-Omaha4 1.5/3 8p',
  [SupportedGameType.CashROENLHOmaha4OneHalfThree9p]: 'Cash ROE NLH-Omaha4 1.5/3 9p',
  [SupportedGameType.CashROENLHOmaha4TwoFour6p]: 'Cash ROE NLH-Omaha4 2/4 6p',
  [SupportedGameType.CashROENLHOmaha4TwoFour9p]: 'Cash ROE NLH-Omaha4 2/4 9p',
  [SupportedGameType.CashROEOmaha456HalfOne6p]: 'Cash ROE Omaha4-5-6 0.5/1 6p',
  [SupportedGameType.CashROEOmaha456OneTwo6p]: 'Cash ROE Omaha4-5-6 1/2 6p',
  [SupportedGameType.CashROEOmaha456OneTwo6pRI2]: 'Cash ROE Omaha4-5-6 1/2 6p RI2',
  [SupportedGameType.CashROEOmaha456OneHalfThree6p]: 'Cash ROE Omaha4-5-6 1.5/3 6p',
  [SupportedGameType.CashROEOmaha456TwoFour6p]: 'Cash ROE Omaha4-5-6 2/4 6p',

  [SupportedGameType.CashOFCRegularHalfOne3p]: 'Cash OFC Regular 0.5/1 3p',
  [SupportedGameType.CashOFCRegularOneTwo3p]: 'Cash OFC Regular 1/2 3p',
  [SupportedGameType.CashOFCRegularOneHalfThree3p]: 'Cash OFC Regular 1.5/3 3p',
  [SupportedGameType.CashOFCRegularTwoFour3p]: 'Cash OFC Regular 2/4 3p',
  [SupportedGameType.CashOFCProgressiveHalfOne3p]: 'Cash OFC Progressive 0.5/1 3p',
  [SupportedGameType.CashOFCProgressiveOneTwo3p]: 'Cash OFC Progressive 1/2 3p',
  [SupportedGameType.CashOFCProgressiveOneHalfThree3p]: 'Cash OFC Progressive 1.5/3 3p',
  [SupportedGameType.CashOFCProgressiveTwoFour3p]: 'Cash OFC Progressive 2/4 3p',

  [SupportedGameType.MMTMainFreeRollNLH]: 'Main Free Roll NLH',
  [SupportedGameType.MMTMainFreeRollPLO4]: 'Main Free Roll PLO4',
  [SupportedGameType.MMTMainFreeRollPLO5]: 'Main Free Roll PLO5',
  [SupportedGameType.MMTMainFreeRollPLO6]: 'Main Free Roll PLO6',

  [SupportedGameType.MMTCrazyRiverNLHAddOn]: 'Crazy River NLH Add-On 125+12',
  [SupportedGameType.MMTDonkeyKickNLHRebuy]: 'Donkey Kick NLH Rebuy 100+10',
  [SupportedGameType.MMTBonnanza150NLHRebuy]: 'Bonnanza 150 NLH Rebuy',
  [SupportedGameType.MMTKongfuPandaNLHDeep200Re]: 'Kongfu Panda NLH deep 200 re',
  [SupportedGameType.MMTSniperShotNLHFreezeout250]: 'Sniper Shot NLH Freezeout 250',
  [SupportedGameType.MMTCoconutBountyNLH]: 'COCO-nuts Bounty NLH 120+50+12',
  [SupportedGameType.MMTTerminatorBountyNLH]: 'Terminator Bounty NLH 100+100',
  [SupportedGameType.MMTDesperadoBountyNLH]: 'Desperado Bounty NLH 150+100',
  [SupportedGameType.MMTLucky777BountyNLH]: 'Lucky 777 Bounty NLH 70+70',
  [SupportedGameType.MMTDuckHuntingNLH]: 'Duck Hunting NLH Bounty 80+40',

  [SupportedGameType.MMTBoiKalaNLH]: 'בואי כלה NLH 100+10',
  [SupportedGameType.MMTRelaxationNLH]: 'Relaxation NLH 100+10',
  [SupportedGameType.MMTAfterParty]: 'After Party',
  [SupportedGameType.MMTMidnightMadnessBounty7030]: 'Midnight Madness Bounty 70+30',

  [SupportedGameType.MMTCenturyNLHRebuy]: 'Century NLH Rebuy 100+10',
  [SupportedGameType.MMTOmahaFeverBountyPLO4]: 'Omaha Fever Bounty PLO4 60+60',
  [SupportedGameType.MMT50CentBountyNLH5050]: '50 Cent Bounty NLH 50+50',
  [SupportedGameType.MMTPacmanBountyNLH5025]: 'Pacman Bounty NLH 50+25',
  [SupportedGameType.MMTPacmanBountyPLO45025]: 'Pacman Bounty PLO4 50+25',
  [SupportedGameType.MMTRainbowBountyPLO5]: 'Rainbow Bounty PLO5 50+25',

  [SupportedGameType.MMTPyromaniaDeepStackNLH30020]: 'Pyromania Deep Stack NLH 300+20',
  [SupportedGameType.MMTWildWestBountyNLH30020]: 'עצמאות76 Bounty NLH 200+100+20',

  [SupportedGameType.MMTChampionsNLH]: 'אלוף האלופים NLH',
  [SupportedGameType.MMTLucky777NLH]: 'Lucky 777 NLH 77+7',
  [SupportedGameType.MMTLucky777PLO4]: 'Lucky 777 PLO4 77+7',
  [SupportedGameType.MMTFullTiltNLH404]: 'Full Tilt NLH 40+4',
  [SupportedGameType.MMTFullTiltPLO5404]: 'Full Tilt PLO5 40+4',

  [SupportedGameType.MMTSatelliteNLH1205]: 'Satellite NLH 120+5',
  [SupportedGameType.MMTSatelliteNLH1005]: 'Satellite NLH 100+5',
  [SupportedGameType.MMTSatelliteNLH605]: 'Satellite NLH 60+5',
  [SupportedGameType.MMTSatelliteNLH505]: 'Satellite NLH 50+5',
  [SupportedGameType.MMTSatelliteNLH303]: 'Satellite NLH 30+3',
};

export const satelliteGameTypesParams: { [key in SupportedGameType]?: Pick<GameSatellite, 'gap' | 'max'> } = {
  [SupportedGameType.MMTMainFreeRollNLH]: { gap: 1, max: 1 },
  [SupportedGameType.MMTMainFreeRollPLO4]: { gap: 1, max: 1 },
  [SupportedGameType.MMTMainFreeRollPLO5]: { gap: 1, max: 1 },
  [SupportedGameType.MMTMainFreeRollPLO6]: { gap: 1, max: 1 },
  [SupportedGameType.MMTSatelliteNLH1205]: { gap: 5, max: null },
  [SupportedGameType.MMTSatelliteNLH1005]: { gap: 5, max: null },
  [SupportedGameType.MMTSatelliteNLH605]: { gap: 5, max: null },
  [SupportedGameType.MMTSatelliteNLH505]: { gap: 5, max: null },
  [SupportedGameType.MMTSatelliteNLH303]: { gap: 5, max: null },
};

export const satelliteGameTypes = Object.keys(satelliteGameTypesParams) as SupportedGameType[];

export const withOfflineSatelliteGameTypes = [
  SupportedGameType.MMTSatelliteNLH1205,
  SupportedGameType.MMTSatelliteNLH1005,
  SupportedGameType.MMTSatelliteNLH605,
  SupportedGameType.MMTSatelliteNLH505,
  SupportedGameType.MMTSatelliteNLH303,
];

export const DEFAULT_MMT_START_AT = 1;
export const gameTypesWithDifferentStartAt: { [key in SupportedGameType]?: number } = {
  [SupportedGameType.MMTMainFreeRollNLH]: 1,
  [SupportedGameType.MMTMainFreeRollPLO4]: 1,
  [SupportedGameType.MMTMainFreeRollPLO5]: 1,
  [SupportedGameType.MMTMainFreeRollPLO6]: 1,

  [SupportedGameType.MMTCrazyRiverNLHAddOn]: 2,
  [SupportedGameType.MMTDonkeyKickNLHRebuy]: 2,
  [SupportedGameType.MMTBonnanza150NLHRebuy]: 2,
  [SupportedGameType.MMTKongfuPandaNLHDeep200Re]: 2,
  [SupportedGameType.MMTSniperShotNLHFreezeout250]: 2,
  [SupportedGameType.MMTCoconutBountyNLH]: 2,
  [SupportedGameType.MMTTerminatorBountyNLH]: 2,
  [SupportedGameType.MMTDesperadoBountyNLH]: 2,
  // [SupportedGameType.MMTLucky777BountyNLH]: 2,
  [SupportedGameType.MMTCenturyNLHRebuy]: 2,
  [SupportedGameType.MMTDuckHuntingNLH]: 1,

  [SupportedGameType.MMTPyromaniaDeepStackNLH30020]: 10,
  [SupportedGameType.MMTWildWestBountyNLH30020]: 10,

  [SupportedGameType.MMTChampionsNLH]: 2,
};

export const gameTypesGivingMMTPoints: SupportedGameType[] = [
  SupportedGameType.MMTCrazyRiverNLHAddOn,
  SupportedGameType.MMTDonkeyKickNLHRebuy,
  SupportedGameType.MMTBonnanza150NLHRebuy,
  SupportedGameType.MMTKongfuPandaNLHDeep200Re,
  SupportedGameType.MMTSniperShotNLHFreezeout250,
  SupportedGameType.MMTCoconutBountyNLH,
  SupportedGameType.MMTTerminatorBountyNLH,
  SupportedGameType.MMTDesperadoBountyNLH,
  SupportedGameType.MMTLucky777BountyNLH,
  SupportedGameType.MMTCenturyNLHRebuy,
  SupportedGameType.MMTDuckHuntingNLH,

  SupportedGameType.MMTPyromaniaDeepStackNLH30020,
  SupportedGameType.MMTWildWestBountyNLH30020,
];

export const disabledCashGameTypes: SupportedGameType[] = [
  SupportedGameType.CashOFCRegularHalfOne3p,
  SupportedGameType.CashOFCRegularOneTwo3p,
  SupportedGameType.CashOFCRegularOneHalfThree3p,
  SupportedGameType.CashOFCRegularTwoFour3p,
];

export const manualApproveMMTGameTypes: SupportedGameType[] = [SupportedGameType.MMTChampionsNLH];

export const mmtGameTypesDefaultStartCount = 4;
export const mmtGameTypesWithDifferentStartCount: { [key in SupportedGameType]?: number } = {
  [SupportedGameType.MMTSatelliteNLH1205]: 5,
  [SupportedGameType.MMTSatelliteNLH1005]: 5,
  [SupportedGameType.MMTSatelliteNLH605]: 5,
  [SupportedGameType.MMTSatelliteNLH505]: 5,
  [SupportedGameType.MMTSatelliteNLH303]: 5,
};
