import React, { useMemo, useState } from 'react';
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { apiFetchTransactions } from '../../services/api';
import DataTable from '../../components/DataTable/DataTable';
import { GetTransactionsDtoFilterEnum } from '@pokerrrr2/server/src/modules/transactions/transactions.dto';
import useAuth from '../../providers/auth/useAuth';
import { UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { getTransactionsTableColumns } from './TransactionsScreen.utils';
import { Transaction } from '@pokerrrr2/server/src/interfaces/transaction.interface';
import SearchField from '../../components/SearchField/SearchField';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { containMatch } from '../../utils/matchers';
import { useGridApiRef } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import AddIcon from '@mui/icons-material/Add';
import CreateUpdateTransactionDialog from './CreateUpdateTransactionDialog';
import { getTransactionDescription } from '../../utils/transactions.util';
import { dateTimeFormat } from '../../utils/date';

export default function TransactionsScreen() {
  const { authUser } = useAuth();
  const [transactionsFilter, setTransactionsFilter] = useState(
    authUser.role === UserRole.ADMIN || authUser.isAccountant
      ? GetTransactionsDtoFilterEnum.AllTransactions
      : GetTransactionsDtoFilterEnum.MyTransactions,
  );
  const [transactionCreateOrUpdate, setTransactionCreateOrUpdate] = useState<Transaction | {} | undefined>();
  const [search, setSearch] = useQueryParam('s', withDefault(StringParam, ''), {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  const transactionsDataTableApiRef = useGridApiRef();

  const { data: transactionsRes, refetch: refetchTransactions } = useQuery({
    queryKey: ['transactions', transactionsFilter],
    queryFn: () => apiFetchTransactions({ filter: transactionsFilter }),
    refetchInterval: 30_000,
  });

  const rows = useMemo(() => {
    let transactions = transactionsRes?.data || [];
    transactions = transactions.filter(transaction => {
      return containMatch(search, [
        transaction.id.toString(),
        transaction.type,
        getTransactionDescription(transaction, authUser)[0],
        dateTimeFormat(transaction.updatedAt),
      ]);
    });
    return transactions;
  }, [transactionsRes, search, authUser]);

  const columns = useMemo(() => getTransactionsTableColumns(authUser, setTransactionCreateOrUpdate), [authUser, setTransactionCreateOrUpdate]);

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, value: GetTransactionsDtoFilterEnum | null) => {
    value && setTransactionsFilter(value);
  };

  const onCreateUpdateTransactionDialogRequestClose = async (refetch: boolean) => {
    setTransactionCreateOrUpdate(undefined);
    if (refetch) await refetchTransactions();
  };

  return (
    <Stack sx={{ flex: 1, p: 3, gap: 3 }}>
      {([UserRole.ADMIN].includes(authUser.role) || authUser.isAccountant) && (
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 3,
            flexWrap: 'wrap',
            alignItems: 'flex-end',
          }}
        >
          <ToggleButtonGroup value={transactionsFilter} color="secondary" exclusive onChange={handleFilterChange}>
            <ToggleButton value={GetTransactionsDtoFilterEnum.MyTransactions}>My Transactions</ToggleButton>
            <ToggleButton value={GetTransactionsDtoFilterEnum.MyPlayersTransactions}>My Players Transactions</ToggleButton>
            {(authUser.role === UserRole.ADMIN || authUser.isAccountant) && (
              <ToggleButton value={GetTransactionsDtoFilterEnum.AllTransactions}>All Transactions</ToggleButton>
            )}
          </ToggleButtonGroup>
          <SearchField value={search} onChange={setSearch} sx={{ ml: 'auto' }} />
          <Tooltip title="Export As CSV">
            <Button sx={{ minWidth: 40 }} onClick={() => transactionsDataTableApiRef.current.exportDataAsCsv()}>
              <FileDownloadIcon />
            </Button>
          </Tooltip>
          {authUser.isAccountant && (
            <Button component="label" variant="outlined" startIcon={<AddIcon />} onClick={() => setTransactionCreateOrUpdate({})}>
              Create Transaction
            </Button>
          )}
        </Stack>
      )}
      <Box sx={{ width: '100%' }}>
        <DataTable
          apiRef={transactionsDataTableApiRef}
          columns={columns}
          rows={rows}
          paginationModel={{ pageSize: 50 }}
          sortModel={[{ field: 'updatedAt', sort: 'desc' }]}
        />
      </Box>
      <CreateUpdateTransactionDialog
        open={Boolean(transactionCreateOrUpdate)}
        initialTransaction={transactionCreateOrUpdate}
        onRequestClose={onCreateUpdateTransactionDialogRequestClose}
      />
    </Stack>
  );
}
