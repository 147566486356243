import React, { ReactNode, useEffect, useMemo } from 'react';
import AuthContext from './AuthContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiAuthLogin, apiAuthLoginAs, apiAuthLogout, apiAuthPasswordReset, apiAuthUser } from '../../services/api';
import { RoutePath } from '../../AppRouter';
import useStorage from '../storage/useStorage';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { storageGet, storageSet, storageRemove } = useStorage();

  const haveAuthorizationToken = Boolean(storageGet('Authorization'));
  const {
    data: authUserRes,
    isFetched: authUserFetched,
    refetch: refetchAuthUser,
    isError: authUserError,
  } = useQuery({
    queryKey: ['authUser', haveAuthorizationToken],
    queryFn: () => apiAuthUser(),
    retry: false,
    enabled: haveAuthorizationToken,
    keepPreviousData: true,
    refetchInterval: 60_000,
  });

  const authUser = useMemo(() => (authUserError ? undefined : authUserRes?.data), [authUserRes, authUserError]);

  useEffect(() => {
    if (authUserError) storageRemove('Authorization');
  }, [authUserError, storageRemove]);

  const { mutate: login, error: loginError } = useMutation({
    mutationFn: apiAuthLogin,
    onError: (res: { message?: string }) => res,
    onSuccess: res => {
      storageSet('Authorization', res.tokenData.token);
      return refetchAuthUser();
    },
  });

  const { mutate: loginAs, error: loginAsError } = useMutation({
    mutationFn: apiAuthLoginAs,
    onError: (res: { message?: string }) => res,
    onSuccess: res => {
      storageSet('Authorization', res.tokenData.token);
      return refetchAuthUser();
    },
  });

  const { mutate: logout, error: logoutError } = useMutation({
    mutationFn: apiAuthLogout,
    onError: (res: { message?: string }) => res,
    onSuccess: () => {
      storageRemove('Authorization');
      return refetchAuthUser();
    },
  });

  const { mutate: passwordReset, error: passwordResetError } = useMutation({
    mutationFn: apiAuthPasswordReset,
    onError: (res: { message?: string }) => res,
    onSuccess: res => window.open(RoutePath.Login, '_self'),
  });

  const shouldRenderChildren = authUserFetched || !haveAuthorizationToken;

  return (
    <AuthContext.Provider
      value={{
        authUser: authUser!,
        refetchAuthUser,
        login,
        loginErrorMessage: loginError?.message,
        loginAs,
        loginAsErrorMessage: loginAsError?.message,
        logout,
        logoutErrorMessage: logoutError?.message,
        passwordReset,
        passwordResetErrorMessage: passwordResetError?.message,
      }}
    >
      {shouldRenderChildren && children}
    </AuthContext.Provider>
  );
};
