import { UserSatellite } from './user.interface';
import { SupportedGameType } from './game.interface';

export type ClubMember = {
  playerId: string;
  displayName: string;
  playerCode: string;
  joinedTs?: number;
  favorite?: boolean;
  approvedBy?: string;
};

export type ClubDetails = {
  _id: { $oid: string };
  name: string;
  clubCode: string;
  description: string;
  _newCapacity: boolean;
  confirmation: boolean;
  hostingCapability: 'OWNER';
  liveGameCount: number;
  maxPlayer: number;
  members: ClubMember[];
  ownerId: string;
  createdAt: number;
  updatedAt: number;
  latestMsgSN: number;
  managers: string[];
};

export enum ClubMessageType {
  leave = 'leave',
  join = 'join',
  joinRequest = 'request',
  joinRejected = 'rejected',
  kick = 'kick',
  promote = 'promote',
  host = 'host',
}

export type ClubMessageBase = {
  _id: { $oid: string };
  senderId: string;
  senderName: string;
  senderCode: string;
  clubId: string;
  message: string;
  sn: number;
  ts: number;
};

export type ClubMessageSystem = ClubMessageBase & {
  system: true;
};

export type ClubMessageSystemWithActor = ClubMessageSystem & {
  actorId: string;
  actorName: string;
};

export type ClubMessageLeave = ClubMessageSystem & {
  type: ClubMessageType.leave;
};

export type ClubMessageJoin = ClubMessageSystem & {
  type: ClubMessageType.join;
};

export type ClubMessageJoinRequest = ClubMessageSystem & {
  type: ClubMessageType.joinRequest;
};

export type ClubMessageJoinRejected = ClubMessageSystemWithActor & {
  type: ClubMessageType.joinRejected;
};

export type ClubMessageKick = ClubMessageSystemWithActor & {
  type: ClubMessageType.kick;
};

export type ClubMessagePromote = ClubMessageSystemWithActor & {
  type: ClubMessageType.promote;
};

export type ClubMessageHost = ClubMessageSystem & {
  type: ClubMessageType.host;
  gameCode: string;
  settings: ClubGameSettings;
};

export type ClubMessage =
  | ClubMessageBase
  | ClubMessageLeave
  | ClubMessageJoin
  | ClubMessageJoinRequest
  | ClubMessageJoinRejected
  | ClubMessageKick
  | ClubMessagePromote
  | ClubMessageHost;

export type ClubAnnounce = {
  _id: { $oid: string };
};

export enum ClubGameStatus {
  PREPARE = 'PREPARE',
  WAITING = 'WAITING',
  PAUSE = 'PAUSE',
  HANDEND = 'HANDEND',
  PLAYING = 'PLAYING',
  END = 'END',
}

export enum ClubGameMode {
  cash = 'cash',
  custom = 'custom',
}

export enum ClubGameRunItTwiceType {
  always = 'always',
  everyHand = 'everyHand',
}

export enum ClubGameStraddleType {
  normal = 'normal',
}

export enum ClubGameROEType {
  NLH = 'nlh',
  PLO4 = 'plo-4',
  PLO5 = 'plo-5',
  PLO6 = 'plo-6',
}

export type ClubGameSettings = {
  name?: string;
  isReloadAdd?: boolean;
  mode?: ClubGameMode;
  confirmation?: boolean;
  viewerMute?: boolean;
  autoMuck?: boolean;
  omaha?: boolean;
  timeBank?: number;
  maxPlayer?: number;
  length?: number;
  bb?: number;
  buyInRangeBBMin?: number;
  buyInRangeBBMax?: number;
  recordPrivacy?: boolean;
  runItTwice?: boolean;
  runItTwiceType?: ClubGameRunItTwiceType;
  straddleType?: ClubGameStraddleType;
  shortDeck?: boolean;
  shortDeckStraightRule?: number;
  ofcType?: 'pineapple';
  roe?: boolean;
  roeTypes?: ClubGameROEType[];
  hiLo?: boolean;
  randomSeat?: boolean;
  needQueue?: boolean;
  straddle?: boolean;
  withDecimal?: boolean;
  allowChat?: boolean;
  detailRakeRec?: boolean;
  serviceCharge?: number;
  serviceChargeUnitMin?: number;
  serviceChargeFreeMin?: number;
  hitNRunEnabled?: boolean;
  disconTB?: number;
  newBlindRule?: boolean;
  freeHost?: boolean;
  timeout?: number;
  isPrivate?: boolean;
  autoStartPlayers?: number;
  timeBankCount?: number;
  timeBankSecs?: number;
  timeBankRefresh?: boolean;
  timeBankCashBonusMins?: number;
  timeBankLimit?: number;
  rakeRatio?: number;
  timeBankSngBonusLevel?: number;
  buyInTicket?: number;
  buyInTips?: number;
  bounty?: number;
  startingChip?: number;
  interval?: number;
  lateRegLevel?: number;
  rebuyLevel?: number;
  rebuyLimit?: number;
  breakMins?: number;
  breakLevel?: number;
  prizePlan?: { n: number; r: number }[];
  extraPrize?: { s: number; n: number; p: string }[];
  defaultAnte?: boolean;
  defaultBlindStructure?: boolean;
  extendRebuyEndTime?: boolean;
  blindStructure?: number[];
  anteStructure?: number[];
  addonChips?: number;
  addonTicket?: number;
  addonTips?: number;
  addonSecs?: number;
  _5Cards?: boolean;
  _6Cards?: boolean;
  isSelectDealer?: boolean;
  avgWonBB100Enabled?: boolean;
};

export type ClubGamePlayer = {
  empty: boolean;
  seat: number;
  playerId?: string;
  playerCode?: string;
  displayName?: string;
  chips?: number;
  stack?: number;
  inRoom?: boolean;
  left?: boolean;
  kick?: boolean;
  isOnline?: boolean;
  disconTBQuota?: number;
  disconTBTs?: number;
  handCount?: number;
  timeoutCount?: number;
  actionTimeout?: number;
};

export type ClubGameRankedPlayer = {
  supportedGameType: SupportedGameType;
  ticketPrice: number;
  addonPrice: number;
  playerId: string;
  playerCode: string;
  displayName: string;
  place?: number;
  handCount: number;
  knockdown?: number;
  bought?: number;
  addon?: number;
  chips: number;
  stack: number;
  winChips?: number;
  bounty?: number;
  buyIn?: number;
  diff?: number;
  rake?: number;
  onlineSatelliteTicketUsedAmount?: number; // only after finish
  satelliteTicket?: UserSatellite; // only after finish
  mmtPoints?: number; // only after finish
};

export type ClubGame = {
  _id: { $oid: string };
  gameCode: string;
  createdAt: number;
  managers: string[];
  owner: ClubMember;
  settings: ClubGameSettings;
  status: ClubGameStatus;
  elaspedTime: number;
  playerCount: number;
  playerCountMax: number;
  players: ClubGamePlayer[];
  serviceCharge: number;
  rake: number;
  ranking: ClubGameRankedPlayer[];
  totalEntries: number;
  ticketPrice: number;
  addonPrice: number;
};

export type ClubGameRequestInfo = {
  roomId: string;
  gameCode: string;
  requestNum: number;
  needConfirm: boolean;
};

export type ClubGameRequestPlayer = {
  ts: number;
  playerId: string;
  playerName: string;
  playerCode: string;
  expireTime: number;
  isDecided: boolean;
  buyInChips?: number;
  reloadChips?: number;
  oldChips?: number;
  addon?: number;
  boughtTimes?: number;
};

export type CreatedGame = {
  _id: { $oid: string };
  gameCode: string;
  serviceCharge: number;
  rake: number;
  clubId: string;
  countTimeOffset: number;
  createdAt: number;
  createdDateStr: string;
  handSN: number;
  managers: string[];
  moveSN: number;
  moves: [];
  owner: ClubMember;
  players: [];
  privateEvents: {};
  ranking: [];
  reloads: {};
  requests: [];
  settings: ClubGameSettings;
  startCountingAt: number;
  state: number;
  status: ClubGameStatus.PREPARE;
  timeout: number;
  updatedAt: number;
};
