import { IsArray, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { GameAction, SupportedGameType, SupportedGameTypeWithOfflineType } from '../../interfaces/game.interface';
import { ClubGameRankedPlayer } from '../../interfaces/club.interface';

export enum GetGamesDtoFilterEnum {
  MyGames = 'MyGames',
  MyPlayersGames = 'MyPlayersGames',
  AllGames = 'AllGames',
  AllGamesHistory = 'AllGamesHistory',
  ActiveGames = 'ActiveGames',
}

export class GetGamesDto {
  @IsEnum(GetGamesDtoFilterEnum)
  public filter?: GetGamesDtoFilterEnum;
}

export class OpenGameDto {
  @IsEnum(SupportedGameType)
  @IsNotEmpty()
  public supportedGameType!: SupportedGameType;

  @IsString()
  @IsOptional()
  public satelliteOnlineGameType?: SupportedGameTypeWithOfflineType | null;
}

export class GameActionDto {
  @IsString()
  @IsNotEmpty()
  public oid!: string;

  @IsEnum(GameAction)
  @IsNotEmpty()
  public action!: GameAction;
}

export class GameUpdateRankingDto {
  @IsArray()
  public ranking!: ClubGameRankedPlayer[];
}
