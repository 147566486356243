import { ceil, floor, round } from 'lodash';

export function roundPrecision(value: number | string, precision: number = 2) {
  return round(Number(value || 0), precision);
}

export function ceilPrecision(value: number | string, precision: number = 2) {
  return ceil(Number(value || 0), precision);
}

export function floorPrecision(value: number | string, precision: number = 2) {
  return floor(Number(value || 0), precision);
}

export function percentage(value: number | string, percent: number = 0) {
  return Number(value || 0) * (1 + percent / 100);
}
