import { createContext } from 'react';

export interface IStorageContext {
  storageHas: (key: string) => boolean;
  storageGet: (key: string) => any;
  storageSet: (key: string, value: any) => void;
  storageRemove: (...keys: string[]) => void;
  storageClearAll: () => void;
}

const StorageContext = createContext<IStorageContext>({} as IStorageContext);
export default StorageContext;
