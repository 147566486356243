import { createContext } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, UseMutateFunction } from '@tanstack/react-query';
import { User } from '@pokerrrr2/server/src/interfaces/user.interface';

export interface IAuthContext {
  login: UseMutateFunction<
    { data: User; tokenData: { token: string; expiresIn: number } },
    {
      message?: string | undefined;
    },
    { code: string; password: string }
  >;
  loginErrorMessage?: string;
  loginAs: UseMutateFunction<
    { data: User; tokenData: { token: string; expiresIn: number } },
    {
      message?: string | undefined;
    },
    { code: string }
  >;
  loginAsErrorMessage?: string;
  logout: UseMutateFunction<
    { data: User },
    {
      message?: string | undefined;
    }
  >;
  logoutErrorMessage?: string;
  passwordReset: UseMutateFunction<
    { data: User },
    {
      message?: string | undefined;
    },
    { code: string; password: string; passwordConfirmation: string }
  >;
  passwordResetErrorMessage?: string;
  authUser: User;
  refetchAuthUser: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<
    QueryObserverResult<
      {
        data: User;
      },
      unknown
    >
  >;
}

export interface IAuthContextOptionalUser extends Omit<IAuthContext, 'user'> {
  user?: User;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export default AuthContext;
