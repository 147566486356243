import React, { useCallback, useMemo } from 'react';
import Dropdown, { DropdownProps } from './Dropdown';
import { TransactionType } from '@pokerrrr2/server/src/interfaces/transaction.interface';

const types: NonNullable<DropdownProps['options']> = Object.values(TransactionType).map(value => ({
  value: value,
  label: value,
}));

interface TransactionTypeDropdownProps {
  value?: TransactionType | null;
  onChange?: (value: TransactionType | null) => void;
  label?: string;
  exclude?: TransactionType[];
}

export default function TransactionTypeDropdown({
  value: valueProp,
  onChange: onChangeProp,
  label = 'Type',
  exclude = [],
}: TransactionTypeDropdownProps) {
  const onChange = useCallback((value?: string | null) => onChangeProp?.((value as TransactionType) || null), [onChangeProp]);
  const value = valueProp === undefined || valueProp === null ? '' : String(valueProp);
  const options = useMemo(() => types.filter(t => !exclude?.includes(t.value as TransactionType)), [exclude]);
  return <Dropdown value={value} onChange={onChange} options={options} label={label} />;
}
