import React from 'react';
import { Box, Stack } from '@mui/material';
import policy1Img from '../../assets/images/policy1.webp';
import policy2Img from '../../assets/images/policy2.webp';
import policy3Img from '../../assets/images/policy3.webp';

function PolicyScreen() {
  return (
    <Stack sx={{ p: 3, flex: 1 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <img src={policy1Img} alt="policy1" width="105%" style={{ margin: '-5% -5% -17% 0' }} />
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <img src={policy2Img} alt="policy2" width="105%" style={{ margin: '-12% -5% -13% 0' }} />
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <img src={policy3Img} alt="policy3" width="105%" style={{ margin: '-12% -5% -50% 0' }} />
      </Box>
    </Stack>
  );
}

export default PolicyScreen;
