import { useMutation } from '@tanstack/react-query';
import { UseMutationOptions } from '@tanstack/react-query/src/types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useCallback, useState } from 'react';

export interface ConfirmDialogMutationParams {
  title?: string;
  text: string;
  onCancel?: () => Promise<void> | void;
}

export function useConfirmDialogMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> & ConfirmDialogMutationParams,
) {
  const { title = 'Are You Sure ?', text, onCancel, ...useMutationOptions } = options;
  const { mutate, mutateAsync, ...restMutation } = useMutation(useMutationOptions);

  const [promiseArguments, setPromiseArguments] = useState<{
    args: TVariables;
    dialogParams?: ConfirmDialogMutationParams;
  } | null>(null);

  const onMutate = useCallback((args: TVariables, dialogParams?: ConfirmDialogMutationParams) => {
    setPromiseArguments({ args, dialogParams });
  }, []);

  const handleNo = () => {
    onCancel?.();
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    mutate(promiseArguments?.args!);
    setPromiseArguments(null);
  };

  const renderConfirmDialogMutation = () => {
    if (!promiseArguments) {
      return null;
    }

    const { dialogParams } = promiseArguments;

    return (
      <Dialog
        maxWidth="xl"
        TransitionProps={{
          onEntered: () => {},
        }}
        open={true}
      >
        <Box sx={{ width: '350px' }} />
        <DialogTitle>{dialogParams?.title || title}</DialogTitle>
        <DialogContent dividers sx={{ whiteSpace: 'pre-wrap' }}>
          {dialogParams?.text || text}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>No</Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return {
    renderConfirmDialogMutation,
    ...restMutation,
    mutate: onMutate,
  };
}
