import React, { useMemo } from 'react';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { User, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import DataTable from '../../components/DataTable/DataTable';
import { useGridApiRef } from '@mui/x-data-grid';
import { getCycleTableColumns } from './CycleScreen.utils';
import useAuth from '../../providers/auth/useAuth';

export interface CycleScreenAdminsTableProps {
  isPrev?: boolean;
  players: User[];
}

export default function CycleScreenAdminsTable({ isPrev, players }: CycleScreenAdminsTableProps) {
  const { authUser } = useAuth();
  const dataTableApiRef = useGridApiRef();

  const rows = useMemo(() => {
    return players.filter(player => player.role === UserRole.ADMIN);
  }, [players]);

  const columns = useMemo(() => getCycleTableColumns(authUser, rows, isPrev), [authUser, rows, isPrev]);

  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 3,
          flexWrap: 'wrap',
          alignItems: 'flex-end',
        }}
      >
        <Typography>Admins</Typography>
        <Stack direction="row" marginLeft="auto">
          <Tooltip title="Export As CSV">
            <Button
              sx={{ minWidth: 40 }}
              onClick={() =>
                dataTableApiRef.current.exportDataAsCsv({
                  fields: ['name', 'inGameName', 'code', 'balance', 'rakeBack', 'total'],
                  fileName: 'Admins',
                })
              }
            >
              <FileDownloadIcon />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <DataTable
          apiRef={dataTableApiRef}
          columns={columns}
          rows={rows}
          paginationModel={{ pageSize: 50 }}
          sortModel={[{ field: 'balance', sort: 'desc' }]}
        />
      </Stack>
    </Stack>
  );
}
