import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { apiFetchCurrentCycleSum, apiFetchPreviousCycleSum, apiFetchUsers } from '../../services/api';
import { UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import CycleScreenAgentTable from './CycleScreenAgentTable';
import { sumBy } from 'lodash';
import { currency } from '../../utils/money';
import CycleScreenAdminsTable from './CycleScreenAdminsTable';
import { roundPrecision } from '../../utils/numbers';
import { dateTimeFormat } from '../../utils/date';

export interface CycleScreenProps {
  isPrev?: boolean;
}

export default function CycleScreen({ isPrev }: CycleScreenProps) {
  const { data: cycleSumRes } = useQuery({
    queryKey: ['cycleSum', isPrev],
    queryFn: () => (isPrev ? apiFetchPreviousCycleSum() : apiFetchCurrentCycleSum()),
    refetchInterval: 30_000,
  });
  const cycleSum = useMemo(() => cycleSumRes?.data, [cycleSumRes]);

  const { data: usersRes } = useQuery({
    queryKey: ['users'],
    queryFn: () => apiFetchUsers(),
    refetchInterval: 30_000,
  });
  const users = useMemo(() => usersRes?.data || [], [usersRes]);

  const { data: agentsRes } = useQuery({
    queryKey: ['agents'],
    queryFn: () => apiFetchUsers({ roles: [UserRole.ADMIN, UserRole.AGENT] }),
  });
  const agents = useMemo(() => agentsRes?.data || [], [agentsRes]);

  const totalBalance = useMemo(() => roundPrecision(sumBy(users, user => (isPrev ? user.cycle.balance : user.balance))), [isPrev, users]);
  const totalRakeBack = useMemo(() => roundPrecision(sumBy(users, user => (isPrev ? user.cycle.rakeBack : user.rakeBack))), [isPrev, users]);
  const totalSum = useMemo(() => roundPrecision(totalBalance + totalRakeBack), [totalBalance, totalRakeBack]);

  const giftsStr = useMemo(() => cycleSum && `${cycleSum.gifts.count} ↠ ${currency(cycleSum.gifts.sum)}`, [cycleSum]);
  const ticketsStr = useMemo(() => cycleSum && `${cycleSum.tickets.count} ↠ ${currency(cycleSum.tickets.sum)}`, [cycleSum]);
  const balancesStr = useMemo(() => cycleSum && `${cycleSum.balances.count} ↠ ${currency(cycleSum.balances.sum)}`, [cycleSum]);
  const satellitesStr = useMemo(() => cycleSum?.satellites && `${cycleSum.satellites.count} ↠ ${currency(cycleSum.satellites.sum)}`, [cycleSum]);

  const dateTimeStr = useMemo(() => cycleSum && `${dateTimeFormat(cycleSum.start)} ↠ ${dateTimeFormat(cycleSum.end)}`, [cycleSum]);

  return (
    <Stack sx={{ flex: 1, p: 3, gap: 3 }}>
      <Stack direction="row" gap="15px">
        <Typography variant="subtitle2">{dateTimeStr}</Typography>
      </Stack>

      <Stack sx={{ whiteSpace: 'nowrap' }}>
        <Stack direction="row" gap="15px">
          <Typography variant="subtitle2">Total Rake:</Typography>
          <Typography variant="subtitle2">{currency(cycleSum?.rake?.total)}</Typography>
        </Stack>
        <Stack direction="row" gap="15px" marginBottom="20px">
          <Typography variant="subtitle2">Club Rake:</Typography>
          <Typography variant="subtitle2">{currency(cycleSum?.rake?.club)}</Typography>
        </Stack>

        <Stack direction="row" gap="15px">
          <Typography variant="subtitle2">Total RakeBack:</Typography>
          <Typography variant="subtitle2">{currency(totalRakeBack)}</Typography>
        </Stack>
        <Stack direction="row" gap="15px" marginBottom="20px">
          <Typography variant="subtitle2">Total Balance:</Typography>
          <Typography variant="subtitle2">{currency(totalBalance)}</Typography>
        </Stack>

        <Stack direction="row" gap="15px" marginBottom="20px">
          <Typography variant="subtitle2">Total Sum:</Typography>
          <Typography variant="subtitle2">{currency(totalSum)}</Typography>
        </Stack>

        <Stack direction="row" gap="15px">
          <Typography variant="subtitle2">Total Gift Transactions:</Typography>
          <Typography variant="subtitle2">{giftsStr}</Typography>
        </Stack>
        <Stack direction="row" gap="15px">
          <Typography variant="subtitle2">Total Ticket Transactions:</Typography>
          <Typography variant="subtitle2">{ticketsStr}</Typography>
        </Stack>
        <Stack direction="row" gap="15px" marginBottom={satellitesStr ? '20px' : undefined}>
          <Typography variant="subtitle2">Total Balance Transactions:</Typography>
          <Typography variant="subtitle2">{balancesStr}</Typography>
        </Stack>

        {satellitesStr && (
          <Stack direction="row" gap="15px">
            <Typography variant="subtitle2">Total Unused Ticket:</Typography>
            <Typography variant="subtitle2">{satellitesStr}</Typography>
          </Stack>
        )}
      </Stack>

      <CycleScreenAdminsTable isPrev={isPrev} players={users} />
      {agents.map(agent => {
        return <CycleScreenAgentTable key={agent.id} isPrev={isPrev} agent={agent} players={users} />;
      })}
    </Stack>
  );
}
