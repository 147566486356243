import React, { useCallback, useMemo, useState } from 'react';
import { Button, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiDeleteUser, apiDemoteUser, apiFetchUsers, apiPromoteUser, apiUpdateUser } from '../../services/api';
import { get, sumBy } from 'lodash';
import { User, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import DataTable from '../../components/DataTable/DataTable';
import useAuth from '../../providers/auth/useAuth';
import { computeUserMutation, computeUserMutationValidation, getUsersTableColumns, UsersScreenRoleFilter } from './UsersScreen.utils';
import { useDataTableConfirmUpdate } from '../../hooks/useDataTableConfirmUpdate';
import { currency } from '../../utils/money';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../AppRouter';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import SearchField from '../../components/SearchField/SearchField';
import { containMatch } from '../../utils/matchers';
import { useGridApiRef } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import useSnackbar from '../../providers/snackbar/useSnackbar';
import { useConfirmDialogMutation } from '../../hooks/useConfirmDialogMutation';
import UpdateSatelliteTicketsDialog from './UpdateSatelliteTicketsDialog';
import UsersDropdown from '../../components/Dropdowns/UsersDropdown';

export default function UsersScreen() {
  const { authUser, refetchAuthUser, loginAs } = useAuth();
  const navigate = useNavigate();
  const [roleFilter, setUsersScreenRoleFilter] = useState(UsersScreenRoleFilter.All);
  const [search, setSearch] = useQueryParam('s', withDefault(StringParam, ''), {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  const [agentId, setAgentId] = useQueryParam('a', withDefault(NumberParam, null), {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  const usersDataTableApiRef = useGridApiRef();
  const [userForSatelliteDialog, setUserForSatelliteDialog] = useState<User | undefined>();

  const { data: usersRes, refetch: refetchUsers } = useQuery({
    queryKey: ['users', agentId],
    queryFn: () => apiFetchUsers({ agentId }),
    refetchInterval: 30_000,
  });

  const { data: agentsRes, refetch: refetchAgents } = useQuery({
    queryKey: ['agents'],
    queryFn: () => apiFetchUsers({ roles: [UserRole.ADMIN, UserRole.AGENT] }),
  });

  const refetchUsersAndAgents = useCallback(async () => {
    return Promise.all([refetchUsers(), refetchAgents()]);
  }, [refetchUsers, refetchAgents]);

  const agents = useMemo(() => agentsRes?.data || [], [agentsRes]);

  const { mutateAsync: usersUpdate } = useMutation({
    mutationFn: apiUpdateUser,
  });

  const rows = useMemo(() => {
    let users = usersRes?.data || [];
    if (roleFilter === UsersScreenRoleFilter.Agents) {
      users = users.filter(u => [UserRole.ADMIN, UserRole.AGENT].includes(u.role));
    } else if (roleFilter === UsersScreenRoleFilter.Admins) {
      users = users.filter(u => u.role === UserRole.ADMIN);
    }
    users = users.filter(u => containMatch(search, [u.inGameName, u.name || '', u.code]));
    return users;
  }, [usersRes, roleFilter, search]);

  const { setSnackbar } = useSnackbar();

  const onRowMutation = useCallback(
    async (newRow: User) => {
      try {
        const response = await usersUpdate({ userId: newRow.id, data: newRow });
        setSnackbar({ children: 'User successfully saved', severity: 'success' });
        await refetchUsersAndAgents();
        await refetchAuthUser();
        return response.data;
      } catch (error) {
        const message = get(error, 'message') || 'Error';
        setSnackbar({ children: message, severity: 'error' });
        throw error;
      }
    },
    [usersUpdate, refetchUsersAndAgents, refetchAuthUser, setSnackbar],
  );
  const { renderDataTableConfirmDialog, processDataTableRowUpdate } = useDataTableConfirmUpdate(
    onRowMutation,
    computeUserMutation,
    computeUserMutationValidation,
  );

  const handleLoginAs = useCallback(
    ({ code }: { code: string }) => {
      navigate(RoutePath.Home);
      loginAs({ code });
    },
    [loginAs, navigate],
  );

  const { mutate: promoteUser, renderConfirmDialogMutation: promoteUserConfirmDialog } = useConfirmDialogMutation({
    text: 'This will set the player as manager in app.',
    mutationFn: apiPromoteUser,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
      await refetchUsers();
    },
    onSuccess: async res => {
      setSnackbar({ children: `User successfully promoted to ${res.data.inGameRole} in app`, severity: 'success' });
      await refetchUsers();
    },
  });

  const { mutate: demoteUser, renderConfirmDialogMutation: demoteUserConfirmDialog } = useConfirmDialogMutation({
    text: 'This will set the player as member in app.',
    mutationFn: apiDemoteUser,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
      await refetchUsers();
    },
    onSuccess: async res => {
      setSnackbar({ children: `User successfully demoted to ${res.data.inGameRole} in app`, severity: 'success' });
      await refetchUsers();
    },
  });

  const { mutate: deleteUser, renderConfirmDialogMutation: deleteUserConfirmDialog } = useConfirmDialogMutation({
    text: 'This will remove the player from the club in app and delete all of his records in the system.',
    mutationFn: apiDeleteUser,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
      await refetchUsersAndAgents();
    },
    onSuccess: async res => {
      setSnackbar({ children: `User successfully deleted`, severity: 'success' });
      await refetchUsersAndAgents();
    },
  });

  const columns = useMemo(
    () =>
      getUsersTableColumns(
        authUser,
        roleFilter,
        processDataTableRowUpdate,
        agents,
        handleLoginAs,
        promoteUser,
        demoteUser,
        deleteUser,
        setUserForSatelliteDialog,
      ),
    [authUser, roleFilter, processDataTableRowUpdate, agents, handleLoginAs, promoteUser, demoteUser, deleteUser, setUserForSatelliteDialog],
  );

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, value: UsersScreenRoleFilter | null) => {
    value && setUsersScreenRoleFilter(value);
  };

  const onUpdateSatelliteTicketsDialogResponse = useCallback(
    async (user: User) => {
      await refetchUsers();
      setUserForSatelliteDialog(user);
    },
    [refetchUsers],
  );

  return (
    <Stack sx={{ flex: 1, p: 3, gap: 3 }}>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 3,
          flexWrap: 'wrap',
          alignItems: 'flex-end',
        }}
      >
        {authUser.role === UserRole.ADMIN && (
          <ToggleButtonGroup value={roleFilter} color="secondary" exclusive onChange={handleFilterChange}>
            <ToggleButton value={UsersScreenRoleFilter.All}>All Users</ToggleButton>
            <ToggleButton value={UsersScreenRoleFilter.Agents}>Agents</ToggleButton>
            <ToggleButton value={UsersScreenRoleFilter.Admins}>Admins</ToggleButton>
          </ToggleButtonGroup>
        )}
        <SearchField value={search} onChange={setSearch} sx={{ ml: 'auto' }} />
        {authUser.role === UserRole.ADMIN && (
          <Stack sx={{ width: '400px' }}>
            <UsersDropdown label="סוכן" value={agentId} users={agents} inNullable onChange={setAgentId} />
          </Stack>
        )}
        <Tooltip title="Export As CSV">
          <Button sx={{ minWidth: 40 }} onClick={() => usersDataTableApiRef.current.exportDataAsCsv()}>
            <FileDownloadIcon />
          </Button>
        </Tooltip>
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <DataTable
          apiRef={usersDataTableApiRef}
          columns={columns}
          rows={rows}
          paginationModel={{ pageSize: 50 }}
          sortModel={[{ field: 'id', sort: 'asc' }]}
          processRowUpdate={processDataTableRowUpdate}
        />
      </Stack>
      <Stack sx={{ padding: 3, borderTop: '1px solid', borderColor: '#E0E0E0', gap: '8px' }}>
        <Typography>Balances Sum: {currency(sumBy(rows, 'balance'))}</Typography>
      </Stack>
      <UpdateSatelliteTicketsDialog
        user={userForSatelliteDialog}
        onRequestClose={() => setUserForSatelliteDialog(undefined)}
        onResponse={onUpdateSatelliteTicketsDialogResponse}
      />
      {renderDataTableConfirmDialog()}
      {promoteUserConfirmDialog()}
      {demoteUserConfirmDialog()}
      {deleteUserConfirmDialog()}
    </Stack>
  );
}
