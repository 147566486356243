import React from 'react';
import { Alert, Avatar, Box, Button, Grid, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import logo from '../../assets/images/logo275.png';
import bg from '../../assets/images/friendly-game-bg.png';
import useAuth from '../../providers/auth/useAuth';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../AppRouter';

function LoginScreen() {
  const { login, loginErrorMessage } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = Object.fromEntries(new FormData(event.currentTarget).entries()) as {
      code?: string;
      password?: string;
    };
    return login({ code: data.code || '', password: data.password || '' });
  };

  return (
    <Stack sx={{ flex: 1 }}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, backgroundColor: 'secondary.main' }} src={logo} />
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField margin="normal" required fullWidth id="code" label="Code" name="code" autoFocus />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Log In
              </Button>
              <Link
                component="button"
                variant="body2"
                onClick={event => {
                  event.preventDefault();
                  navigate(RoutePath.PasswordReset);
                }}
              >
                Reset Password
              </Link>
              {loginErrorMessage && (
                <Alert severity="error" sx={{ whiteSpace: 'pre-wrap', mt: 1 }}>
                  {loginErrorMessage.split(', ').join('\n').split(',').join('\n')}
                </Alert>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default LoginScreen;
