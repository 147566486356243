import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar as MuiAppBar, Box, Button, Container, IconButton, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import logo from '../assets/images/logo275.png';
import useAuth from '../providers/auth/useAuth';
import { RoutePath } from '../AppRouter';
import { SYSTEM_PLAYER_CODE, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { useMutation } from '@tanstack/react-query';
import { apiActionCloseCycle, apiActionRenewToken, apiActionSocketReconnect, apiActionSyncClubMembers, apiActionSyncGold } from '../services/api';
import { get } from 'lodash';
import IconButtonMenu from '../components/IconButtonMenu/IconButtonMenu';
import useSnackbar from '../providers/snackbar/useSnackbar';
import { useConfirmDialogMutation } from '../hooks/useConfirmDialogMutation';

export default function AppBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { authUser, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();

  const tabs = useMemo(
    () => [
      {
        label: 'משחקים',
        onClick: () => navigate(RoutePath.Games),
        visible: true,
      },
      {
        label: 'כרטיסים',
        onClick: () => !location.pathname.startsWith(RoutePath.Tickets) && navigate(RoutePath.Tickets),
        visible: true,
      },
      {
        label: 'טבלת ניקוד',
        onClick: () => !location.pathname.startsWith(RoutePath.Points) && navigate(RoutePath.Points),
        visible: true,
      },
      {
        label: 'שחקנים',
        onClick: () => !location.pathname.startsWith(RoutePath.Users) && navigate(RoutePath.Users),
        visible: [UserRole.ADMIN, UserRole.AGENT].includes(authUser.role),
      },
      {
        label: 'העברות',
        onClick: () => !location.pathname.startsWith(RoutePath.Transactions) && navigate(RoutePath.Transactions),
        visible: [UserRole.ADMIN].includes(authUser.role) || authUser.isAccountant,
      },
      {
        label: 'מחזור נוכחי',
        onClick: () => !location.pathname.startsWith(RoutePath.CurrentCycle) && navigate(RoutePath.CurrentCycle),
        visible: authUser.isAccountant,
      },
      {
        label: 'מחזור קודם',
        onClick: () => !location.pathname.startsWith(RoutePath.PreviousCycle) && navigate(RoutePath.PreviousCycle),
        visible: authUser.isAccountant,
      },
      {
        label: 'תקנון',
        onClick: () => !location.pathname.startsWith(RoutePath.Policy) && navigate(RoutePath.Policy),
        visible: true,
      },
    ],
    [location.pathname, navigate, authUser],
  );

  const settings = useMemo(() => [{ label: 'Logout', onClick: logout, visible: true }], [logout]);

  const onError = useCallback(
    async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
    },
    [setSnackbar],
  );

  const onSuccess = useCallback(
    async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Success';
      setSnackbar({ children: message, severity: 'success' });
    },
    [setSnackbar],
  );

  const { mutate: actionCloseCycle, renderConfirmDialogMutation: actionCloseCycleConfirmDialog } = useConfirmDialogMutation({
    text: 'This cannot be undone',
    mutationFn: apiActionCloseCycle,
    onError,
    onSuccess,
  });

  const { mutate: actionSyncGold } = useMutation({
    mutationFn: apiActionSyncGold,
    onError,
    onSuccess,
  });

  const { mutate: actionSyncClubMembers } = useMutation({
    mutationFn: apiActionSyncClubMembers,
    onError,
    onSuccess,
  });

  const { mutate: actionSocketReconnect } = useMutation({
    mutationFn: apiActionSocketReconnect,
    onError,
    onSuccess,
  });

  const { mutate: actionRenewToken } = useMutation({
    mutationFn: apiActionRenewToken,
    onError,
    onSuccess,
  });

  const actions = useMemo(
    () => [
      {
        label: 'Close Cycle',
        onClick: actionCloseCycle,
        visible: authUser.role === UserRole.ADMIN,
      },
      {
        label: 'Sync Gold',
        onClick: actionSyncGold,
        visible: authUser.code === SYSTEM_PLAYER_CODE,
      },
      {
        label: 'Sync Club Members',
        onClick: actionSyncClubMembers,
        visible: authUser.code === SYSTEM_PLAYER_CODE,
      },
      {
        label: 'Socket Reconnect',
        onClick: actionSocketReconnect,
        visible: authUser.code === SYSTEM_PLAYER_CODE,
      },
      {
        label: 'Renew Token',
        onClick: actionRenewToken,
        visible: authUser.code === SYSTEM_PLAYER_CODE,
      },
    ],
    [actionCloseCycle, authUser.role, authUser.code, actionSyncGold, actionSyncClubMembers, actionSocketReconnect, actionRenewToken],
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  if (!authUser) return null;

  return (
    <MuiAppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img src={logo} alt="logo" width="20px" height="18px" />
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => location.pathname !== RoutePath.Home && navigate(RoutePath.Home)}
          >
            SPC
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current authUser"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {tabs
                .filter(t => t.visible)
                .map(tab => (
                  <MenuItem
                    key={tab.label}
                    onClick={() => {
                      handleCloseNavMenu();
                      tab.onClick();
                    }}
                  >
                    <Typography textAlign="center">{tab.label}</Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img src={logo} alt="logo" width="20px" height="18px" />
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => location.pathname !== RoutePath.Home && navigate(RoutePath.Home)}
          >
            SPC
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {tabs
              .filter(t => t.visible)
              .map(tab => (
                <Button
                  key={tab.label}
                  onClick={() => {
                    handleCloseNavMenu();
                    tab.onClick();
                  }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {tab.label}
                </Button>
              ))}
          </Box>

          <Stack direction="row" sx={{ flexGrow: 0, flexWrap: 'nowrap' }}>
            <IconButtonMenu icon={<KeyboardCommandKeyIcon />} filter={s => s.visible} items={actions} />
            <IconButtonMenu
              icon={<AccountCircleIcon />}
              filter={s => s.visible}
              items={settings}
              header={
                <Stack sx={{ py: 1, px: 2 }} flexDirection="row" alignItems="center" gap={1}>
                  <AccountCircleIcon />
                  <Typography variant="subtitle2">{authUser.inGameName}</Typography>
                </Stack>
              }
            />
          </Stack>
        </Toolbar>
      </Container>
      {actionCloseCycleConfirmDialog()}
    </MuiAppBar>
  );
}
