import React, { useMemo } from 'react';
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ReactComponent as MedalIcon } from '../../assets/svgs/medal.svg';
import { useQuery } from '@tanstack/react-query';
import { apiFetchPointsTables } from '../../services/api';
import { getUserNameTag } from '../../utils/users.util';
import { currency } from '../../utils/money';

function PointsScreen() {
  const { data: pointsTablesRes } = useQuery({
    queryKey: ['points-tables'],
    queryFn: () => apiFetchPointsTables(),
    refetchInterval: 30_000,
  });

  const currentTableUsers = useMemo(() => pointsTablesRes?.data.current || [], [pointsTablesRes]);
  const prevTableUsers = useMemo(() => pointsTablesRes?.data.prev || [], [pointsTablesRes]);

  const renderTable = (tableUsers: typeof currentTableUsers, colors: [string, string, string]) => {
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Stack sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <MedalIcon height={20} />
                </Stack>
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                שם
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                נקודות
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableUsers.map((user, index) => (
              <TableRow
                key={`${user.id} ${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  height: index < 3 ? 50 : undefined,
                  backgroundColor: colors[index],
                }}
              >
                <TableCell align="center" sx={{ fontWeight: index < 3 ? 'bold' : undefined }}>
                  {index + 1}
                </TableCell>
                <TableCell align="right" dir="ltr" sx={{ fontWeight: index < 3 ? 'bold' : undefined }}>
                  {getUserNameTag(user)}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: index < 3 ? 'bold' : undefined }}>
                  {currency(user.mmtPoints, { withSymbol: false })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!tableUsers.length && (
          <Stack sx={{ padding: 5 }}>
            <Typography variant="subtitle1" textAlign="center">
              אין נתונים להצגה
            </Typography>
          </Stack>
        )}
      </TableContainer>
    );
  };

  return (
    <Stack sx={{ p: 3, gap: 10 }} dir="rtl">
      <Stack>
        <Typography variant="h4" align="center">
          מחזור נוכחי
        </Typography>
        {renderTable(currentTableUsers, ['#FFDF72BB', '#DBDBDB99', '#FEBB7477'])}
      </Stack>
      <Stack>
        <Typography variant="h6" align="center">
          מחזור קודם
        </Typography>
        {renderTable(prevTableUsers, ['#FFDF7277', '#DBDBDB55', '#FEBB7444'])}
      </Stack>
    </Stack>
  );
}

export default PointsScreen;
