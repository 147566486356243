import { cloneDeep, mapValues } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

export default class StorageService {
  static storage: { [key: string]: any } = mapValues(localStorage, v => {
    try {
      return JSON.parse(v);
    } catch (e) {
      return v;
    }
  });

  static setStore: Dispatch<SetStateAction<{ [key: string]: any }>>;

  static has(key: string) {
    return key in StorageService.storage;
  }

  static get(key: string): any {
    return cloneDeep(StorageService.storage[key]);
  }

  static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
    StorageService.storage[key] = value;
    StorageService.setStore(StorageService.storage);
  }

  static remove(...keys: string[]) {
    keys.forEach(key => {
      localStorage.removeItem(key);
      delete StorageService.storage[key];
    });
    StorageService.setStore(StorageService.storage);
  }

  static clearAll() {
    localStorage.clear();
    StorageService.storage = {};
    StorageService.setStore(StorageService.storage);
  }
}
