import { User } from '@pokerrrr2/server/src/interfaces/user.interface';
import StorageService from './storage';
import { addQueryParamsToUrl } from '../utils/url.util';
import { GameActionDto, GameUpdateRankingDto, GetGamesDto, OpenGameDto } from '@pokerrrr2/server/src/modules/games/games.dto';
import { Game } from '@pokerrrr2/server/src/interfaces/game.interface';
import { Dconfig } from '@pokerrrr2/server/src/interfaces/dconfig.interface';
import { IdParamDto } from '@pokerrrr2/server/src/modules/generic.dto';
import { CreateUpdateTransactionDto, GetTransactionsDto } from '@pokerrrr2/server/src/modules/transactions/transactions.dto';
import { Transaction } from '@pokerrrr2/server/src/interfaces/transaction.interface';
import { GetUsersDto } from '@pokerrrr2/server/src/modules/users/users.dto';
import { CycleSum } from '@pokerrrr2/server/src/interfaces/cycle.interface';

export enum ApiMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

function apiRequest(
  method: ApiMethod,
  uri: string,
  params?: {
    query?: Record<string, any>;
    body?: Record<string, any>;
  },
) {
  let url = uri;
  if (params?.query && Object.keys(params?.query).length) {
    url = addQueryParamsToUrl(url, params.query);
  }

  let body;
  if (params?.body && Object.keys(params?.body).length) {
    body = JSON.stringify(params.body);
  }

  const headers: Headers = new Headers();
  headers.set('Content-Type', 'application/json');
  const authorization = StorageService.get('Authorization');
  if (authorization) headers.set('Authorization', `Bearer ${authorization}`);

  return fetch(url, { method, body, headers }).then(async response => {
    const res = {
      ok: response.ok,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      url: response.url,
    };

    if (res.status === 401) window.location.reload();

    const data = await response.json();
    // eslint-disable-next-line no-throw-literal
    if (!res.ok) throw { ...res, ...data };
    return data;
  });
}

///////////////////////////////////////////////////////////////
///////////////////// Auth and Users
///////////////////////////////////////////////////////////////

export const apiAuthLogin = (data: {
  code: string;
  password: string;
}): Promise<{
  data: User;
  tokenData: { token: string; expiresIn: number };
}> => {
  return apiRequest(ApiMethod.POST, `/api/auth/login`, { body: data });
};

export const apiAuthLogout = (): Promise<{ data: User }> => {
  return apiRequest(ApiMethod.POST, `/api/auth/logout`);
};

export const apiAuthPasswordReset = (data: {
  code: string;
  password: string;
  passwordConfirmation: string;
}): Promise<{
  data: User;
}> => {
  return apiRequest(ApiMethod.POST, `/api/auth/password-reset`, { body: data });
};

export const apiAuthLoginAs = (data: {
  code: string;
}): Promise<{
  data: User;
  tokenData: { token: string; expiresIn: number };
}> => {
  return apiRequest(ApiMethod.POST, `/api/auth/login-as`, { body: data });
};

export const apiAuthUser = (): Promise<{ data: User }> => {
  return apiRequest(ApiMethod.GET, `/api/auth/user`);
};

export const apiFetchUsers = (query?: GetUsersDto): Promise<{ data: User[] }> => {
  return apiRequest(ApiMethod.GET, '/api/users', { query });
};

export const apiUpdateUser = ({ userId, data }: { userId: number; data: Partial<User> }): Promise<{ data: User }> => {
  return apiRequest(ApiMethod.PUT, `/api/users/${userId}`, { body: data });
};

export const apiPromoteUser = ({ userId }: { userId: number }): Promise<{ data: User }> => {
  return apiRequest(ApiMethod.PUT, `/api/users/${userId}/promote`);
};

export const apiDemoteUser = ({ userId }: { userId: number }): Promise<{ data: User }> => {
  return apiRequest(ApiMethod.PUT, `/api/users/${userId}/demote`);
};

export const apiDeleteUser = ({ userId }: { userId: number }): Promise<{ data: User }> => {
  return apiRequest(ApiMethod.DELETE, `/api/users/${userId}`);
};

///////////////////////////////////////////////////////////////
///////////////////// Dconfig
///////////////////////////////////////////////////////////////

export const apiFetchDconfig = (key: string): Promise<{ data: Dconfig }> => {
  return apiRequest(ApiMethod.GET, `/api/dconfigs/${key}`);
};

///////////////////////////////////////////////////////////////
///////////////////// Actions
///////////////////////////////////////////////////////////////

export const apiActionCloseCycle = (): Promise<{ message: string }> => {
  return apiRequest(ApiMethod.GET, `/api/actions/close-cycle`);
};

export const apiActionSocketReconnect = (): Promise<{ message: string }> => {
  return apiRequest(ApiMethod.GET, `/api/actions/socket-reconnect`);
};

export const apiActionRenewToken = (): Promise<{ message: string }> => {
  return apiRequest(ApiMethod.GET, `/api/actions/renew-token`);
};

export const apiActionSyncGold = (): Promise<{ message: string }> => {
  return apiRequest(ApiMethod.GET, `/api/actions/sync-gold`);
};

export const apiActionSyncClubMembers = (): Promise<{ message: string }> => {
  return apiRequest(ApiMethod.GET, `/api/actions/sync-club-members`);
};

///////////////////////////////////////////////////////////////
///////////////////// Games
///////////////////////////////////////////////////////////////

export const apiFetchGames = (query: GetGamesDto): Promise<{ data: Game[] }> => {
  return apiRequest(ApiMethod.GET, '/api/games', { query });
};

export const apiFetchGame = ({ id }: IdParamDto): Promise<{ data: Game }> => {
  return apiRequest(ApiMethod.GET, `/api/games/${id}`, {});
};

export const apiOpenGame = (data: OpenGameDto): Promise<{ data: Game }> => {
  return apiRequest(ApiMethod.POST, `/api/games/open`, { body: data });
};

export const apiGameAction = (data: GameActionDto): Promise<{ data: Game }> => {
  return apiRequest(ApiMethod.POST, `/api/games/action`, { body: data });
};

export const apiGameUpdateRanking = ({ id, ranking }: IdParamDto & GameUpdateRankingDto): Promise<{ data: Game }> => {
  return apiRequest(ApiMethod.POST, `/api/games/${id}/ranking`, { body: { ranking } });
};

///////////////////////////////////////////////////////////////
///////////////////// Transactions
///////////////////////////////////////////////////////////////

export const apiFetchTransactions = (
  data: GetTransactionsDto,
): Promise<{
  data: Transaction[];
}> => {
  return apiRequest(ApiMethod.GET, '/api/transactions', {
    query: data,
  });
};

export const apiTransactionCreate = (body: CreateUpdateTransactionDto): Promise<{ data: Transaction }> => {
  return apiRequest(ApiMethod.POST, `/api/transactions`, { body });
};

export const apiTransactionUpdate = ({
  id,
  ...body
}: IdParamDto & CreateUpdateTransactionDto): Promise<{
  data: Transaction;
}> => {
  return apiRequest(ApiMethod.PUT, `/api/transactions/${id}`, { body });
};

///////////////////////////////////////////////////////////////
///////////////////// points
///////////////////////////////////////////////////////////////

export const apiFetchPointsTables = (): Promise<{
  data: {
    current: Pick<User, 'id' | 'code' | 'inGameName' | 'mmtPoints'>[];
    prev: Pick<User, 'id' | 'code' | 'inGameName' | 'mmtPoints'>[];
  };
}> => {
  return apiRequest(ApiMethod.GET, '/api/users/points');
};

///////////////////////////////////////////////////////////////
///////////////////// cycle
///////////////////////////////////////////////////////////////

export const apiFetchCurrentCycleSum = (): Promise<{
  data: CycleSum;
}> => {
  return apiRequest(ApiMethod.GET, '/api/cycle/current');
};

export const apiFetchPreviousCycleSum = (): Promise<{
  data: CycleSum;
}> => {
  return apiRequest(ApiMethod.GET, '/api/cycle/previous');
};
