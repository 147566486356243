import React, { useCallback, useMemo } from 'react';
import Dropdown, { DropdownPropsOption } from './Dropdown';
import { OFFLINE_GAME_TYPE, OFFLINE_GAME_TYPE_NAME, SupportedGameType, SupportedGameTypeName } from '@pokerrrr2/server/src/interfaces/game.interface';
import { cloneDeep } from 'lodash';

export const games: DropdownPropsOption[] = [
  { value: null, label: <wbr /> },
  ...Object.values(SupportedGameType).map(value => ({
    value,
    label: SupportedGameTypeName[value],
  })),
];

interface GamesDropdownProps {
  value?: SupportedGameType | null;
  onChange?: (value: SupportedGameType | null) => void;
  label?: string;
  filter?: (value: DropdownPropsOption) => void;
  withOfflineGameValue?: boolean;
}

export default function GamesDropdown({
  value: valueProp,
  onChange: onChangeProp,
  label = 'Game',
  filter,
  withOfflineGameValue,
}: GamesDropdownProps) {
  const onChange = useCallback((value?: string | null) => onChangeProp?.((value as SupportedGameType) || null), [onChangeProp]);
  const value = valueProp === undefined || valueProp === null ? '' : String(valueProp);
  const options = useMemo(() => {
    let gamesOptions = cloneDeep(games);
    if (withOfflineGameValue) gamesOptions.splice(1, 0, { value: OFFLINE_GAME_TYPE, label: OFFLINE_GAME_TYPE_NAME });
    if (filter) gamesOptions = gamesOptions.filter(filter);
    return gamesOptions;
  }, [filter, withOfflineGameValue]);
  return <Dropdown value={value} onChange={onChange} options={options} label={label} />;
}
