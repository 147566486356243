import React from 'react';
import { SYSTEM_PLAYER_CODE, User, UserInGameRole, UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { GridActionsCellItem, GridCellParams, GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { filter, keyBy, sum } from 'lodash';
import UsersDropdown from '../../components/Dropdowns/UsersDropdown';
import { getRakeDisplay, getUserNameTag } from '../../utils/users.util';
import LockResetIcon from '@mui/icons-material/LockReset';
import PinIcon from '@mui/icons-material/PinOutlined';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import LockIcon from '@mui/icons-material/Lock';
import UserRoleDropdown from '../../components/Dropdowns/UserRoleDropdown';
import { roundPrecision } from '../../utils/numbers';
import { currency } from '../../utils/money';
import { SupportedGameTypeName } from '@pokerrrr2/server/src/interfaces/game.interface';
import { Stack } from '@mui/material';
import LocalActivityIcon from '@mui/icons-material/LocalActivityOutlined';

export function computeUserMutationValidation(newRow: User, oldRow: User) {
  if (newRow.balance !== oldRow.balance && !newRow.agentId) {
    return `To update the balance, the user must have an agent.`;
  }
  return null;
}

export function computeUserMutation(newRow: User, oldRow: User) {
  if (newRow.name !== oldRow.name) {
    return `Name from '${oldRow.name}' to '${newRow.name}'`;
  }
  if (newRow.phoneNumber !== oldRow.phoneNumber) {
    return `Phone Number from '${oldRow.phoneNumber}' to '${newRow.phoneNumber}'`;
  }
  if (newRow.cup !== oldRow.cup) {
    return `Cup from '${oldRow.cup}' to '${newRow.cup}'`;
  }
  if (newRow.rakePercentage !== oldRow.rakePercentage) {
    return `Rake Percentage from '${oldRow.rakePercentage}' to '${newRow.rakePercentage}'`;
  }
  if (newRow.personalRakePercentage !== oldRow.personalRakePercentage) {
    return `Personal Rake Percentage from '${oldRow.personalRakePercentage}' to '${newRow.personalRakePercentage}'`;
  }
  if (newRow.isRunner !== oldRow.isRunner) {
    return `Is Runner from '${oldRow.isRunner}' to '${newRow.isRunner}'`;
  }
  if (newRow.isAccountant !== oldRow.isAccountant) {
    return `Is Accountant from '${oldRow.isAccountant}' to '${newRow.isAccountant}'`;
  }
  if (newRow.agentId !== oldRow.agentId) {
    const oldName = oldRow.agent ? getUserNameTag(oldRow.agent) : 'None';
    const newName = newRow.agent ? getUserNameTag(newRow.agent) : 'None';
    return `Agent from '${oldName}' to '${newName}'`;
  }
  if (newRow.inPasswordResetProcess !== oldRow.inPasswordResetProcess) {
    return `Password Reset Process from '${oldRow.inPasswordResetProcess}' to '${newRow.inPasswordResetProcess}'`;
  }
  if (newRow.role !== oldRow.role) {
    return `Role from '${oldRow.role}' to '${newRow.role}'`;
  }
  return null;
}

export enum UsersScreenRoleFilter {
  All = 'All',
  Admins = 'Admins',
  Agents = 'Agents',
}

export const getUsersTableColumns = (
  authUser: User,
  roleFilter: UsersScreenRoleFilter,
  processRowUpdate: (newRow: User, oldRow: User) => Promise<User | undefined>,
  agents: User[],
  loginAs: (args: { code: string }) => void,
  promoteUser: (args: { userId: number }) => void,
  demoteUser: (args: { userId: number }) => void,
  deleteUser: (args: { userId: number }) => void,
  setUserForSatelliteDialog: (user: User) => void,
): GridColDef[] => {
  const agentsMap = keyBy(agents, 'id');
  return filter([
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'name',
      headerName: 'שם',
      width: 150,
      editable: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'טלפון',
      width: 150,
      editable: true,
    },
    {
      field: 'inGameName',
      headerName: 'שם באפליקציה',
      width: 150,
    },
    {
      field: 'code',
      headerName: 'קוד שחקן',
      width: 100,
    },
    {
      field: 'eligible',
      headerName: 'יתרה לשחק',
      width: 120,
      type: 'number',
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { balance, cup, liveRanking } = row as User;
        const liveDiffSum = sum(Object.values(liveRanking).map(rank => rank.diff));
        return roundPrecision(-cup + balance + liveDiffSum);
      },
    },
    {
      field: 'balance',
      headerName: 'יתרה באפליקציה',
      width: 150,
      type: 'number',
    },
    {
      field: 'cup',
      headerName: 'קרדיט',
      width: 100,
      type: 'number',
      editable: true,
    },
    {
      field: 'liveGamesProfitSum',
      headerName: 'רווח ממשחקים שעדיין רצים',
      width: 200,
      type: 'number',
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { liveRanking } = row as User;
        return sum(Object.values(liveRanking).map(rank => rank.diff));
      },
    },
    [UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) && {
      field: 'liveGamesRakeSum',
      headerName: 'רייק ממשחקים שעדיין רצים',
      width: 200,
      type: 'number',
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { liveRanking } = row as User;
        return sum(Object.values(liveRanking).map(rank => rank.rake));
      },
    },
    {
      field: 'mmtPoints',
      headerName: 'נקודות',
      width: 150,
      type: 'number',
    },
    {
      field: 'satellites',
      headerName: 'כרטיסים בתוקף',
      width: 350,
      type: 'number',
      sortable: false,
      cellClassName: 'white-space-pre',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { satellites } = row as User;
        return satellites
          .filter(t => !t.usedAt)
          .map(t => (t.onlineGameType ? `Online: ${SupportedGameTypeName[t.onlineGameType]}` : `Offline: ${currency(t.prize)}`))
          .join('\n');
      },
    },
    ([UserRole.ADMIN].includes(authUser.role) || authUser.isAccountant) && {
      field: 'rakeTotal',
      headerName: 'רייק שיצר טוטאל',
      width: 150,
      type: 'number',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rake } = row;
        return currency(roundPrecision(rake.total));
      },
    },
    ([UserRole.ADMIN].includes(authUser.role) || authUser.isAccountant) && {
      field: 'rakeClub',
      headerName: 'רייק שיצר למועדון',
      width: 150,
      type: 'number',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rake } = row;
        return currency(roundPrecision(rake.club));
      },
    },
    ([UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) || authUser.isAccountant) && {
      field: 'rakeAgent',
      headerName: 'רייק שיצר לסוכן',
      width: 150,
      type: 'number',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rake } = row;
        return currency(roundPrecision(rake.agent));
      },
    },
    ([UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) || authUser.isAccountant) && {
      field: 'rakePersonal',
      headerName: 'רייק שיצר לשחקן בחזרה',
      width: 190,
      type: 'number',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const { rake } = row;
        return currency(roundPrecision(rake.personal));
      },
    },
    {
      field: 'lastCycle',
      headerName: 'סגירת מחזור קודם',
      width: 300,
      sortable: false,
      cellClassName: 'white-space-pre',
      valueGetter: ({ row }: GridValueGetterParams<User>) => {
        const {
          cycle: { balance, rakeBack, rake, mmtPoints },
        } = row;
        return (
          `previous balance: ${currency(balance)}` +
          `\nprevious rakeback: ${currency(rakeBack)}` +
          `\nprevious rake: ${getRakeDisplay(rake, authUser)}` +
          `\nprevious points: ${currency(mmtPoints, { withSymbol: false })}`
        );
      },
    },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'personalRakePercentage',
      headerName: 'Personal Rake Percentage',
      width: 170,
      type: 'number',
      editable: true,
    },
    [UsersScreenRoleFilter.Agents, UsersScreenRoleFilter.Admins].includes(roleFilter) &&
      [UserRole.ADMIN].includes(authUser.role) && {
        field: 'rakePercentage',
        headerName: 'Rake Percentage',
        width: 170,
        type: 'number',
        editable: true,
      },
    [UsersScreenRoleFilter.Agents, UsersScreenRoleFilter.Admins].includes(roleFilter) &&
      [UserRole.ADMIN].includes(authUser.role) && {
        field: 'rakeBack',
        headerName: 'Rake Back',
        width: 150,
        type: 'number',
      },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'agentId',
      headerName: 'סוכן',
      width: 350,
      valueGetter: ({ row }: GridValueGetterParams<User>) => row.agent && getUserNameTag(row.agent),
      renderCell: ({ row }: GridCellParams<User>) => (
        <UsersDropdown
          label=""
          value={row.agentId}
          users={agents}
          inNullable
          onChange={(value: number | null) =>
            processRowUpdate(
              {
                ...row,
                agentId: value,
                agent: value === null ? undefined : agentsMap[value],
              },
              row,
            )
          }
        />
      ),
    },
    [UserRole.ADMIN].includes(authUser.role)
      ? {
          field: 'role',
          headerName: 'תפקיד',
          width: 150,
          sortable: false,
          renderCell: ({ row }: GridCellParams<User>) => (
            <UserRoleDropdown
              label=""
              value={row.role}
              onChange={(value: UserRole | null) => processRowUpdate({ ...row, role: value || UserRole.PLAYER }, row)}
            />
          ),
        }
      : {
          field: 'role',
          headerName: 'תפקיד',
          width: 100,
        },
    [UserRole.ADMIN].includes(authUser.role) && {
      field: 'isRunner',
      headerName: 'Is Runner',
      width: 120,
      type: 'boolean',
      editable: true,
    },
    [UserRole.ADMIN].includes(authUser.role) &&
      authUser.isAccountant && {
        field: 'isAccountant',
        headerName: 'Is Accountant',
        width: 120,
        type: 'boolean',
        editable: true,
      },
    [UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) && {
      field: 'actions',
      type: 'actions',
      getActions: ({ row }: GridRowParams<User>) =>
        filter([
          <GridActionsCellItem icon={<LocalActivityIcon />} label="Satellite Tickets" showInMenu onClick={() => setUserForSatelliteDialog(row)} />,
          row.inPasswordResetProcess ? (
            <GridActionsCellItem
              icon={<LockIcon />}
              label="Cancel Password Reset Process"
              showInMenu
              onClick={() => processRowUpdate({ ...row, inPasswordResetProcess: false }, row)}
            />
          ) : (
            <GridActionsCellItem
              icon={<LockResetIcon />}
              label="Initiate Password Reset Process"
              showInMenu
              onClick={() => processRowUpdate({ ...row, inPasswordResetProcess: true }, row)}
            />
          ),
          authUser.code === SYSTEM_PLAYER_CODE && row.inGameId && row.inGameRole === UserInGameRole.MEMBER && (
            <GridActionsCellItem
              icon={
                <Stack sx={{ position: 'relative', justifyContent: 'center' }}>
                  <ArrowDropUpIcon sx={{ position: 'absolute' }} />
                  <SmartphoneIcon sx={{ position: 'absolute' }} />
                </Stack>
              }
              label="Promote To Menager In App"
              showInMenu
              onClick={() => promoteUser({ userId: row.id })}
            />
          ),
          authUser.code === SYSTEM_PLAYER_CODE && row.inGameId && row.inGameRole === UserInGameRole.MANAGER && (
            <GridActionsCellItem
              icon={
                <Stack sx={{ position: 'relative', justifyContent: 'center' }}>
                  <ArrowDropDownIcon sx={{ position: 'absolute' }} />
                  <SmartphoneIcon sx={{ position: 'absolute' }} />
                </Stack>
              }
              label="Demote To Member In App"
              showInMenu
              onClick={() => demoteUser({ userId: row.id })}
            />
          ),
          authUser.code === SYSTEM_PLAYER_CODE && (
            <GridActionsCellItem icon={<PinIcon />} label="Login As" showInMenu onClick={() => loginAs({ code: row.code })} />
          ),
          authUser.code === SYSTEM_PLAYER_CODE && (
            <GridActionsCellItem icon={<DeleteForeverIcon />} label="Delete" showInMenu onClick={() => deleteUser({ userId: row.id })} />
          ),
        ]),
    },
  ] as GridColDef[]);
};
