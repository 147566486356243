import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiFetchGames, apiFetchUsers, apiGameAction, apiGameUpdateRanking, apiOpenGame } from '../../services/api';
import DataTable from '../../components/DataTable/DataTable';
import { GetGamesDtoFilterEnum } from '@pokerrrr2/server/src/modules/games/games.dto';
import useAuth from '../../providers/auth/useAuth';
import { UserRole } from '@pokerrrr2/server/src/interfaces/user.interface';
import { getGamesTableColumns, parseGameName } from './GamesScreen.utils';
import GamesDropdown from '../../components/Dropdowns/GamesDropdown';
import { get, keyBy, sumBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../AppRouter';

import {
  disabledCashGameTypes,
  Game,
  satelliteGameTypes,
  SupportedGameType,
  SupportedGameTypeName,
  withOfflineSatelliteGameTypes,
} from '@pokerrrr2/server/src/interfaces/game.interface';
import { useConfirmDialogMutation } from '../../hooks/useConfirmDialogMutation';
import UpdateGameRankingDialog from './UpdateGameRankingDialog';
import SearchField from '../../components/SearchField/SearchField';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { getUserNameTag } from '../../utils/users.util';
import { containMatch } from '../../utils/matchers';
import { useGridApiRef } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import useSnackbar from '../../providers/snackbar/useSnackbar';
import { roundPrecision } from '../../utils/numbers';
import { DropdownPropsOption } from '../../components/Dropdowns/Dropdown';
import { currency } from '../../utils/money';

export default function GamesScreen() {
  const { authUser } = useAuth();
  const [gamesFilter, setGamesFilter] = useState(authUser.role === UserRole.ADMIN ? GetGamesDtoFilterEnum.AllGames : GetGamesDtoFilterEnum.MyGames);
  const [supportedGameType, setSupportedGameType] = useState<SupportedGameType | null>(null);
  const [satelliteOnlineGameType, setSatelliteOnlineGameType] = useState<SupportedGameType | null>(null);
  const { setSnackbar } = useSnackbar();
  const [gameForUpdateRanking, setGameForUpdateRanking] = useState<Game | undefined>();
  const [search, setSearch] = useQueryParam('s', withDefault(StringParam, ''), {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  const gamesDataTableApiRef = useGridApiRef();
  const navigate = useNavigate();

  const { data: gamesRes, refetch: refetchGames } = useQuery({
    queryKey: ['games', gamesFilter],
    queryFn: () => apiFetchGames({ filter: gamesFilter }),
    refetchInterval: 30_000,
  });

  const { data: usersRes } = useQuery({
    queryKey: ['users'],
    queryFn: () => apiFetchUsers(),
    refetchInterval: 30_000,
  });

  const clearOpenGameSelections = () => {
    setSatelliteOnlineGameType(null);
    setSupportedGameType(null);
  };

  const { mutate: openGame, renderConfirmDialogMutation: openGameConfirmDialog } = useConfirmDialogMutation({
    text: 'This will open a new game.',
    mutationFn: apiOpenGame,
    onError: async (res: { message?: string }) => {
      clearOpenGameSelections();
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
      await refetchGames();
    },
    onSuccess: async res => {
      clearOpenGameSelections();
      setSnackbar({ children: `Game successfully opened ${res.data.code}`, severity: 'success' });
      await refetchGames();
    },
    onCancel: clearOpenGameSelections,
  });

  const { mutate: gameAction, renderConfirmDialogMutation: gameActionConfirmDialog } = useConfirmDialogMutation({
    text: 'This will apply the action.',
    mutationFn: apiGameAction,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
      await refetchGames();
    },
    onSuccess: async res => {
      setSnackbar({ children: `Action successfully applied to game ${res.data.code}`, severity: 'success' });
      await refetchGames();
    },
  });

  const { mutate: updateGameRanking } = useMutation({
    mutationFn: apiGameUpdateRanking,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
      await refetchGames();
    },
    onSuccess: async res => {
      setSnackbar({ children: `Game ranking successfully saved for game ${res.data.code}`, severity: 'success' });
      await refetchGames();
    },
  });

  const rows = useMemo(() => {
    let games = gamesRes?.data || [];
    const usersCodeMap = keyBy(usersRes?.data || [], 'code');
    games = games.filter(game => {
      const scoreStrArr = game.clubGame.ranking?.map(r => {
        return `${r.playerCode} ${usersCodeMap[r.playerCode] ? getUserNameTag(usersCodeMap[r.playerCode]) : ''}`;
      });
      return containMatch(search, [game.code, parseGameName(game), ...scoreStrArr]);
    });
    return games;
  }, [gamesRes, usersRes, search]);

  const columns = useMemo(
    () => getGamesTableColumns(authUser, gameAction, usersRes?.data || [], setGameForUpdateRanking, navigate),
    [authUser, gameAction, usersRes, setGameForUpdateRanking, navigate],
  );

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, value: GetGamesDtoFilterEnum | null) => {
    value && setGamesFilter(value);
  };

  const rowsUsersScoresSum = useMemo(() => {
    const usersCodeMap = keyBy(usersRes?.data || [], 'code');
    const usersScoresSum: { [key: string]: { code: string; name: string; sum: number } } = {};
    rows.forEach(row => {
      row.clubGame.ranking?.forEach(r => {
        if (usersCodeMap[r.playerCode]) {
          if (!usersScoresSum[r.playerCode]) {
            usersScoresSum[r.playerCode] = {
              code: r.playerCode,
              name: getUserNameTag(usersCodeMap[r.playerCode]),
              sum: 0,
            };
          }
          usersScoresSum[r.playerCode].sum = roundPrecision(usersScoresSum[r.playerCode].sum + (r.diff || 0));
        }
      });
    });
    return usersScoresSum;
  }, [rows, usersRes]);

  const showSetSatelliteOnlineGameTypeDropdown = useMemo(
    () => !!supportedGameType && satelliteGameTypes.includes(supportedGameType),
    [supportedGameType],
  );

  const canOpen = useMemo(
    () => !!supportedGameType && (!showSetSatelliteOnlineGameTypeDropdown || !!satelliteOnlineGameType),
    [supportedGameType, satelliteOnlineGameType, showSetSatelliteOnlineGameTypeDropdown],
  );

  const satelliteOnlineGameTypeFilter = useCallback(
    (option: DropdownPropsOption) => !option.value?.startsWith('cash-') && !satelliteGameTypes.includes(option.value as SupportedGameType),
    [],
  );

  const satelliteOnlineGameTypeWithOfflineGameValue = useMemo(
    () => Boolean(supportedGameType && withOfflineSatelliteGameTypes.includes(supportedGameType)),
    [supportedGameType],
  );

  const cashGameTypeFilter = useCallback(
    (option: DropdownPropsOption) =>
      !option.value || (option.value?.startsWith('cash-') && !disabledCashGameTypes.includes(option.value as SupportedGameType)),
    [],
  );
  const satelliteMTTGameTypeFilter = useCallback((option: DropdownPropsOption) => !option.value || !option.value?.startsWith('cash-'), []);

  return (
    <Stack sx={{ flex: 1, p: 3, gap: 3 }}>
      {([UserRole.ADMIN].includes(authUser.role) || authUser.isRunner) && (
        <Stack sx={{ gap: 3, flexDirection: 'row', flexWrap: 'wrap' }}>
          <Box sx={{ flex: 1, maxWidth: '400px', minWidth: '300px' }}>
            <GamesDropdown
              value={supportedGameType}
              onChange={value => {
                setSatelliteOnlineGameType(null);
                setSupportedGameType(value);
              }}
              filter={cashGameTypeFilter}
              label="Open Cash Game"
            />
          </Box>
          <Box sx={{ flex: 1, maxWidth: '400px', minWidth: '300px' }}>
            <GamesDropdown
              value={supportedGameType}
              onChange={value => {
                setSatelliteOnlineGameType(null);
                setSupportedGameType(value);
              }}
              filter={satelliteMTTGameTypeFilter}
              label="Open MTT Game"
            />
          </Box>
          {showSetSatelliteOnlineGameTypeDropdown && (
            <Box sx={{ flex: 1, maxWidth: '400px', minWidth: '300px' }}>
              <GamesDropdown
                value={satelliteOnlineGameType}
                onChange={value => setSatelliteOnlineGameType(value)}
                label="Prize Ticket For"
                filter={satelliteOnlineGameTypeFilter}
                withOfflineGameValue={satelliteOnlineGameTypeWithOfflineGameValue}
              />
            </Box>
          )}
          <Button
            variant="outlined"
            size="small"
            disabled={!canOpen}
            onClick={() =>
              canOpen &&
              supportedGameType &&
              openGame(
                {
                  supportedGameType,
                  satelliteOnlineGameType,
                },
                { text: `This will open a new ${SupportedGameTypeName[supportedGameType]}` },
              )
            }
          >
            Open
          </Button>
        </Stack>
      )}
      {([UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) || authUser.isRunner) && (
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 3,
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <ToggleButtonGroup value={gamesFilter} color="secondary" exclusive onChange={handleFilterChange}>
            <ToggleButton value={GetGamesDtoFilterEnum.MyGames}>My Games</ToggleButton>
            {[UserRole.ADMIN, UserRole.AGENT].includes(authUser.role) && (
              <ToggleButton value={GetGamesDtoFilterEnum.MyPlayersGames}>My Players Games</ToggleButton>
            )}
            {(authUser.role === UserRole.ADMIN || authUser.isRunner) && <ToggleButton value={GetGamesDtoFilterEnum.AllGames}>All Games</ToggleButton>}
            {authUser.role === UserRole.ADMIN && <ToggleButton value={GetGamesDtoFilterEnum.AllGamesHistory}>All Games History</ToggleButton>}
          </ToggleButtonGroup>
          <Button color="secondary" onClick={() => navigate(RoutePath.ActiveGames)}>
            Active Games
          </Button>
          <SearchField value={search} onChange={setSearch} sx={{ ml: 'auto' }} />
          <Tooltip title="Export As CSV">
            <Button sx={{ minWidth: 40 }} onClick={() => gamesDataTableApiRef.current.exportDataAsCsv()}>
              <FileDownloadIcon />
            </Button>
          </Tooltip>
        </Stack>
      )}
      <Box sx={{ width: '100%' }}>
        <DataTable
          apiRef={gamesDataTableApiRef}
          columns={columns}
          rows={rows}
          paginationModel={{ pageSize: 50 }}
          sortModel={[{ field: 'clubGame.createdAt', sort: 'desc' }]}
        />
      </Box>
      {[UserRole.ADMIN].includes(authUser.role) && (
        <>
          <Stack direction="row" gap="10px">
            <Typography variant="h6">Club Rake Sum:</Typography>
            <Typography variant="h6">{currency(sumBy(rows, 'raking.club'))}</Typography>
          </Stack>
          <Stack direction="row" gap="10px">
            <Typography variant="h6">Total Rake Sum:</Typography>
            <Typography variant="h6">{currency(sumBy(rows, 'raking.total'))}</Typography>
          </Stack>
        </>
      )}
      <Stack sx={{ width: '100%' }}>
        <Typography variant="h6">Games Players Sum</Typography>
        <table style={{ width: 0, borderSpacing: 15 }}>
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Sum</th>
            </tr>
          </thead>

          <tbody style={{ gap: 10 }}>
            {Object.values(rowsUsersScoresSum).map(userScoresSum => (
              <tr key={userScoresSum.code} style={{ gap: 10 }}>
                <td>#{userScoresSum.code}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{userScoresSum.name}</td>
                <td>{userScoresSum.sum}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Stack>
      <UpdateGameRankingDialog
        game={gameForUpdateRanking}
        users={usersRes?.data || []}
        onRequestClose={() => setGameForUpdateRanking(undefined)}
        onSave={updateGameRanking}
      />
      {openGameConfirmDialog()}
      {gameActionConfirmDialog()}
    </Stack>
  );
}
