import { createTheme } from '@mui/material';
import { green } from '@mui/material/colors';

class StylesService {
  static colors = {
    primary: '#111111',
    secondary: '#AD3507',
    lightGray: '#f5f5f5',
    gray: '#94999a',
    darkGray: '#6d6d6d',
    lightBlack: '#434343',
    black: '#1a1a1a',
    white: '#ffffff',
    darkRed: '#bc0a07',
    blue: '#5288de',
    darkBlue: '#103d85',
    lightBlue: '#e2f0f6', // lightBlueHighlight
    lighterBlue: '#f2f8fb', // lighterBlueHover
    purple: '#7255a3',
    background: '#f0f3f5',
    green: green[500],
  };

  static theme = createTheme({
    palette: {
      primary: { main: StylesService.colors.primary },
      secondary: { main: StylesService.colors.secondary },
      background: {
        default: StylesService.colors.background,
        paper: StylesService.colors.background,
      },
      common: {
        black: StylesService.colors.black,
        white: StylesService.colors.white,
      },
      text: {
        primary: StylesService.colors.black,
        secondary: StylesService.colors.lightBlack,
      },
    },
    typography: {
      fontFamily: 'Roboto',
      fontSize: 14,
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: 14,
            backgroundColor: StylesService.colors.lightBlack,
            maxWidth: '50vw',
            whiteSpace: 'pre-wrap',
          },
          arrow: {
            color: StylesService.colors.lightBlack,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '&[dir="rtl"] label': {
              left: 'auto',
              right: '0.15rem',
              transformOrigin: 'top right',
              textAlign: 'right',
              marginRight: '1.75rem',
            },
            '&[dir="rtl"] legend': {
              left: 'auto',
              right: 'auto',
              transformOrigin: 'top right',
              textAlign: 'right',
              marginRight: '0.15rem',
            },
          },
        },
      },
    },
  });
}

export default StylesService;
