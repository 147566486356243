import React, { useCallback } from 'react';
import { Card, MenuItem, Stack, Typography } from '@mui/material';
import LocalActivityIcon from '@mui/icons-material/LocalActivityOutlined';
import { SupportedGameTypeName } from '@pokerrrr2/server/src/interfaces/game.interface';
import { dateTimeFormat } from '../../utils/date';
import { currency } from '../../utils/money';
import StylesService from '../../services/styles';
import { getPlayerLiveBalanceAndSatellites, User } from '@pokerrrr2/server/src/interfaces/user.interface';
import ActionsMenuButton from '../../components/MenuButton/ActionsMenuButton';
import { useConfirmDialogMutation } from '../../hooks/useConfirmDialogMutation';
import { apiUpdateUser } from '../../services/api';
import { cloneDeep, get } from 'lodash';
import useSnackbar from '../../providers/snackbar/useSnackbar';

interface SatelliteTicketsProps {
  user: User;
  enableMenu?: boolean;
  onResponse?: (user: User) => void;
}

function SatelliteTickets({ user, enableMenu, onResponse }: SatelliteTicketsProps) {
  const { id, balance, liveRanking, satellites } = user;

  const { liveSatellites } = getPlayerLiveBalanceAndSatellites(balance, liveRanking, satellites);

  const { setSnackbar } = useSnackbar();
  const { mutate: updateUser, renderConfirmDialogMutation: updateUserConfirmDialog } = useConfirmDialogMutation({
    text: `This will only change the ticket 'used' state.`,
    mutationFn: apiUpdateUser,
    onError: async (res: { message?: string }) => {
      const message = get(res, 'message') || 'Error';
      setSnackbar({ children: message, severity: 'error' });
    },
    onSuccess: async ({ data }) => {
      setSnackbar({ children: `User satellite tickets successfully updated`, severity: 'success' });
      onResponse?.(data);
    },
  });

  const onMarkAsUsed = useCallback(
    (ticketIndex: number) => {
      const satellitesClone = cloneDeep(satellites);
      satellitesClone[ticketIndex].usedAt = new Date();
      updateUser({ userId: id, data: { satellites: satellitesClone } });
    },
    [id, satellites, updateUser],
  );

  const onMarkAsNotUsed = useCallback(
    (ticketIndex: number) => {
      const satellitesClone = cloneDeep(satellites);
      satellitesClone[ticketIndex].usedAt = null;
      updateUser({ userId: id, data: { satellites: satellitesClone } });
    },
    [id, satellites, updateUser],
  );

  if (!liveSatellites.length) {
    return (
      <Typography variant="h5" textAlign="center">
        אין כרטיסים
      </Typography>
    );
  }

  return (
    <Stack sx={{ gap: 3, flexDirection: 'row-reverse', flexWrap: 'wrap' }}>
      {liveSatellites.map((satelliteTicket, index) => {
        const key = `${new Date(satelliteTicket.createdAt).getTime()}-${index}`;
        const isOnline = !!satelliteTicket.onlineGameType;
        const isOnlineReminder = !!satelliteTicket.onlineGameType && !!satelliteTicket.prize;
        const isUsed = !!satelliteTicket.usedAt;
        const isUsedInLive = satelliteTicket.usedAt === true;
        return (
          <Card
            key={key}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '300px',
              height: '200px',
              p: 2,
              border: `5px double ${StylesService.colors.darkBlue}`,
              overflowY: 'auto',
              opacity: isUsed ? 0.3 : 1,
              backgroundColor: isUsed ? StylesService.colors.lighterBlue : undefined,
            }}
          >
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <LocalActivityIcon style={{ fontSize: 30 }} sx={{ color: isOnline ? StylesService.colors.blue : StylesService.colors.purple }} />
              <Typography variant="h6">{isOnline ? 'Online Ticket' : 'Offline Ticket'}</Typography>
              <Typography variant="h6" marginLeft="auto" fontWeight="bold">
                {isUsed ? 'שומש' : 'בתוקף'}
              </Typography>
              {enableMenu && (
                <Stack sx={{ marginTop: '-15px', marginRight: '-15px' }}>
                  <ActionsMenuButton>
                    {isUsed && !isUsedInLive && <MenuItem onClick={() => onMarkAsNotUsed(index)}>Mark As Not Used</MenuItem>}
                    {!isUsed && <MenuItem onClick={() => onMarkAsUsed(index)}>Mark As Used</MenuItem>}
                  </ActionsMenuButton>
                </Stack>
              )}
            </Stack>
            <Typography variant="subtitle2" dir="rtl" marginTop={1}>
              זכייה בטורניר: {SupportedGameTypeName[satelliteTicket.gameType]}
            </Typography>
            <Typography variant="subtitle2" dir="rtl">
              בתאריך: {dateTimeFormat(satelliteTicket.createdAt)}
            </Typography>
            {isOnline && !isOnlineReminder && (
              <>
                <Typography variant="subtitle1" dir="rtl" marginTop={1}>
                  כניסה חינם ל:
                </Typography>
                <Typography variant="subtitle2">- {SupportedGameTypeName[satelliteTicket.onlineGameType!]}</Typography>
              </>
            )}
            {isOnline && isOnlineReminder && (
              <>
                <Typography variant="subtitle1" dir="rtl" marginTop={1}>
                  סכום זכייה ישירות לבלאנס:
                </Typography>
                <Typography variant="subtitle2" fontSize={20}>
                  {currency(satelliteTicket.prize)}
                </Typography>
              </>
            )}
            {!isOnline && (
              <>
                <Typography variant="subtitle1" dir="rtl" marginTop={1}>
                  סכום זכייה:
                </Typography>
                <Typography variant="subtitle2" fontSize={20}>
                  {currency(satelliteTicket.prize)}
                </Typography>
              </>
            )}
            {satelliteTicket.usedAt && satelliteTicket.usedAt === true && (
              <Typography variant="subtitle2" dir="rtl" marginTop={1}>
                שומש במשחק שרץ כעט
              </Typography>
            )}
            {satelliteTicket.usedAt && satelliteTicket.usedAt !== true && (
              <Typography variant="subtitle2" dir="rtl" marginTop={1}>
                שומש בתאריך: {dateTimeFormat(satelliteTicket.usedAt!)}
              </Typography>
            )}
          </Card>
        );
      })}
      {updateUserConfirmDialog()}
    </Stack>
  );
}

export default SatelliteTickets;
