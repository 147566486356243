export const degToRad = (deg: number) => {
  return (deg * Math.PI) / 180;
};

export const GetPosOnEllipse = (
  n_items: number,
  index: number,
  a: number,
  b: number,
  center_x: number,
  center_y: number,
  width: number,
  height: number,
) => {
  const angleRad = degToRad(360 * (index / n_items) + 90);
  const x = a * Math.cos(angleRad) + center_x - width / 2;
  const y = b * Math.sin(angleRad) + center_y - height / 2;
  return [x, y];
};
